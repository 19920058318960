<script>
  // import { link } from "svelte-routing";
  // import Icon from "./components/Icon.svelte";
  import { _ } from "svelte-i18n";
</script>

<section>
  <div>
    <!-- <a href="/" use:link>
      <Icon id="left" title="Retourner sur la page principale" size="40" />
    </a> -->
    <h2>{$_("404")}</h2>
  </div>
</section>

<style>
  section {
    max-width: 960px;
    min-height: calc(100vh - 4rem - 4rem);
    padding: 2.5rem 1rem 1rem 1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
</style>
