<script>
  export let id;
  export let title = "";
  export let size = 25;
  // let color = "white"
</script>

{#if id == "email"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m718.78 527.64c1.2094-112.13-88.117-207.33-184.17-211.61-150.67-6.7228-206.84 102.4-208.36 198.26-1.7207 108.35 29.339 218.95 165.61 218.95 46.807 0 219.42-45.575 227.03-160.76m-0.11032-287.43v478.41m23.771-32.117c164.17 2.6647 233.47-164.75 234.62-286.27 1.805-191.37-180.45-388.38-433.45-390.72-306.54-2.8448-487.66 243.96-484.02 470.15 4.2394 263.65 204 454.01 461.79 460.23 103.74 2.4997 225.87-53.452 305.42-105.52"
        fill="none"
        stroke-width="64"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "js"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m9.5308 9.6334h1004.5v1004.5h-1004.5zm896.72 531.09-72.934 46.704c-5.7832-9.6562-10.692-20.633-21.111-28.27-9.8481-7.2177-17.803-12.582-35.154-12.582-23.023 0-27.859 2.0507-37.366 10.814-9.8839 9.1105-13.021 19.635-13.021 35.868 0 12.175 7.767 22.661 15.108 29.56 7.5074 6.696 51.574 26.753 69.605 34.388 27.347 11.436 68.711 31.803 94.538 61.411 17.517 20.697 29.145 45.66 28.403 83.799-0.85068 43.738-14.602 70.228-39.126 94.779-24.358 24.349-61.645 36.524-111.86 36.524-23.69 0-47.985-0.82737-71.321-8.2178-46.264-14.652-83.655-44.122-104.8-86.305l74.739-43.729c14.533 20.233 15.751 27.539 34.632 39.964 20.401 11.771 34.704 17.882 55.391 17.882 21.021 0 40.429-5.013 51.606-13.535 11.178-8.5222 16.315-22.501 16.315-38.328 0-14.204-6.8447-24.559-16.624-32.27-29.314-18.34-50.775-27.358-84.838-41.568-61.817-21.524-101.57-69.377-103.35-128.79-0.75189-37.279 4.6433-67.115 38.592-99.284 24.829-23.527 60.513-33.516 105.56-33.516 64.331 1.4849 96.911 25.078 127.02 74.704zm-445.7-69.979 92.794 0.57369-0.86054 345.36c-0.0209 20.563-10.116 64.212-48.333 93.225-39.191 29.752-99.54 28.041-138.38 19.182-39.707-9.0574-70.274-36.684-94.034-78.941l75.805-46.591c12.03 20 17.701 32.125 31.542 40.949 11.19 7.1346 39.197 13.044 61.507-0.17287 15.867-9.3992 19.372-30.74 20.124-50.44z"
        fill-rule="evenodd"
        style="paint-order:fill markers stroke"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "r"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="matrix(1.373 0 0 1.3729 15.61 126.31)" fill-rule="evenodd">
        <path
          d="m361.45 485.94c-199.12 0-360.55-108.11-360.55-241.47 0-133.36 161.42-241.47 360.55-241.47 199.12 0 360.55 108.11 360.55 241.47 0 133.36-161.42 241.47-360.55 241.47zm55.188-388.53c-151.35 0-274.05 73.908-274.05 165.08s122.7 165.08 274.05 165.08c151.35 0 263.05-50.529 263.05-165.08 0-114.51-111.7-165.08-263.05-165.08z"
          fill="none"
          stroke-width="14.567"
        />
        <path
          d="m550 377s21.822 6.585 34.5 13c4.399 2.226 12.01 6.668 17.5 12.5 5.378 5.712 8 11.5 8 11.5l86 145-139 0.062-65-122.06s-13.31-22.869-21.5-29.5c-6.832-5.531-9.745-7.5-16.5-7.5h-33.026l0.026 158.97-123 0.052v-406.09h247s112.5 2.029 112.5 109.06-107.5 115-107.5 115zm-53.5-135.98-74.463-0.048-0.037 69.05 74.5-0.024s34.5-0.107 34.5-35.125c0-35.722-34.5-33.853-34.5-33.853z"
          stroke-width=".52002"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "github"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        transform="matrix(.39302 0 0 -.39302 3.8199 1079.3)"
        d="m1294.5 2702.5c-712.36 0-1290.1-577.63-1290.1-1290.2 0-570.03 369.64-1053.6 882.33-1224.2 64.551-11.881 88.074 27.959 88.074 62.175 0 30.652-1.1087 111.76-1.7424 219.39-358.87-77.936-434.59 172.98-434.59 172.98-58.69 149.06-143.28 188.74-143.28 188.74-117.14 79.996 8.8708 78.412 8.8708 78.412 129.5-9.1085 197.61-132.98 197.61-132.98 115.08-197.14 302-140.19 375.5-107.16 11.722 83.322 45.067 140.19 81.896 172.43-286.48 32.553-587.69 143.28-587.69 637.67 0 140.82 50.294 256.06 132.82 346.2-13.306 32.632-57.581 163.87 12.673 341.45 0 0 108.27 34.691 354.75-132.27 102.89 28.672 213.29 42.928 322.99 43.483 109.62-0.5545 219.95-14.811 322.99-43.483 246.32 166.96 354.44 132.27 354.44 132.27 70.412-177.57 26.137-308.81 12.91-341.45 82.688-90.134 132.59-205.37 132.59-346.2 0-495.66-301.69-604.72-589.04-636.64 46.255-39.839 87.52-118.57 87.52-238.88 0-172.51-1.5841-311.67-1.5841-353.96 0-34.533 23.286-74.689 88.708-62.096 512.29 171 881.61 654.22 881.61 1224.1 0 712.59-577.71 1290.2-1290.3 1290.2"
        fill-rule="evenodd"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "svelte"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      ><path
        id="front"
        d="m444.86 888.31a181.14 181.14 0 0 1-194.33-72.089 167.25 167.25 0 0 1-28.573-126.7 148.55 148.55 0 0 1 5.4176-21.059l4.2816-13.107 11.709 8.7381a295.17 295.17 0 0 0 88.866 44.215l8.7381 2.534-0.78642 8.7381a51.555 51.555 0 0 0 9.5245 33.642 54.613 54.613 0 0 0 58.545 21.67 51.118 51.118 0 0 0 13.981-6.1167l227.54-145.23a47.36 47.36 0 0 0 21.408-31.807 50.419 50.419 0 0 0-8.7381-38.185 54.613 54.613 0 0 0-58.545-21.67 49.982 49.982 0 0 0-13.981 6.1166l-87.381 55.487a166.9 166.9 0 0 1-46.224 20.272 181.05 181.05 0 0 1-193.99-72.002 167.42 167.42 0 0 1-28.399-126.7 157.29 157.29 0 0 1 71.041-105.38l228.24-145.23a166.46 166.46 0 0 1 45.962-20.272 180.97 180.97 0 0 1 194.25 72.089 167.25 167.25 0 0 1 28.661 126.7 176.07 176.07 0 0 1-5.4176 21.234l-4.369 13.107-11.622-8.7381a295.17 295.17 0 0 0-89.128-44.564l-8.7381-2.534 0.78642-8.7381a51.205 51.205 0 0 0-9.2624-33.904 54.438 54.438 0 0 0-58.196-21.059 49.982 49.982 0 0 0-13.981 6.1166l-227.98 144.96a47.622 47.622 0 0 0-21.408 31.719 51.03 51.03 0 0 0 8.7381 38.273 54.613 54.613 0 0 0 58.108 21.67 52.428 52.428 0 0 0 13.981-6.1167l87.381-55.399a162.62 162.62 0 0 1 46.312-20.36 180.88 180.88 0 0 1 194.25 72.002 167.42 167.42 0 0 1 28.661 126.7 157.29 157.29 0 0 1-71.041 105.38l-227.98 145.23a167.6 167.6 0 0 1-46.312 20.36m422.4-731.99c-90.876-130.02-270.36-168.56-400.12-85.895l-227.89 145.23a261.27 261.27 0 0 0-118.14 174.76 275.51 275.51 0 0 0 27.088 176.86 261.62 261.62 0 0 0-39.059 97.692 278.39 278.39 0 0 0 47.622 210.76c90.876 130.02 270.36 168.56 400.12 85.895l227.89-144.62a262.14 262.14 0 0 0 118.14-174.76 275.42 275.42 0 0 0-27.175-176.77 263.28 263.28 0 0 0 39.147-97.692 278.74 278.74 0 0 0-47.622-210.76"
      /></g
    >
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "d3"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(10.332 0 0 10.336 -5140.8 -6718.9)"
      ><path
        d="m499.58 655.24v19.951c7.7162 0 16.996-0.19807 25.254 6.8917 4.3597 3.7427 8.2703 9.1522 8.2703 18.488 0 7.5378-3.2192 15.095-8.886 19.54-7.3709 5.7815-15.131 6.2189-24.512 6.2189v20.014c13.5 0 31.397-2.4646 42.682-15.805 5.8046-6.8614 10.603-16.043 10.603-30.409 0-14.431-6.1994-26.137-15.731-33.642-10.011-7.882-19.426-11.247-37.681-11.247z"
      /><path
        d="m535.67 655.01c10.259 7.1973 13.605 10.417 18.625 19.951l14.016-0.0631c3.8848 0 6.9132 3.0305 6.9132 7.292 0 4.3563-2.8035 8.3653-6.9132 8.3653h-8.7126c1.7993 7.4814 1.4521 12.438 0 19.761h8.6494c3.1574 0 7.0711 2.557 7.0711 7.7655 0 4.2616-3.4297 7.8918-7.0711 7.8918h-14.079c-3.4093 7.6708-9.5018 14.584-18.498 19.887h33.461c8.0502 0 16.858-4.8621 21.077-12.169 4.0669-6.4449 8.523-20.756-1.5682-33.099 0 0 5.0946-6.8617 6.3881-12.776 1.5107-6.9078-0.92312-16.303-5.4603-22.45-5.5546-6.8386-11.181-10.211-23.139-10.211-10.313 0-21.234-0.16415-30.759-0.14576z"
      /></g
    >
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "leaflet"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m76.847 454.06s-3.9322 18.585-7.9314 46.916l313.96 468.02-14.697 16.394-299.33-483.93c-2.5712 18.287-5.1581 40.51-6.7469 64.684-4.0755 62.02-8.0107 173.8 43.078 292.8 51.089 118.99 121.83 153.28 190.43 157.88 52.399 3.5321 79.667-29.659 99.413-56.572 19.747-26.917 64.97-25.691 75.401-99.603 10.446-73.911-21.852-150.78-95.841-236.92-73.989-86.128-286.8-157.02-297.64-169.67z"
    /><path
      d="m878.17 5.7883c-14.798 23.987-352.23 197.98-453.51 361.31-101.27 163.33-130.17 291.95-93.453 412.84 36.708 120.88 106.89 115.43 146.89 155.29 40.007 39.881 94.283 88.268 180.97 68.73 113.51-25.593 222.89-101.35 277.3-313.67 54.407-212.32 18.733-398.25-4.2642-500.9-22.983-102.65-53.919-183.61-53.919-183.61zm-5.7424 35.848-385.17 901.76-24.012-24.107z"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "three"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke-linejoin="round" stroke-width="32">
      <path d="m282.97 986.78-233.13-943.69 934.54 269.04z" />
      <path d="m516.95 177.64 116.48 471.99-467.01-134.56z" />
      <path d="m401.07 579.08-57.854-234.47 232.02 66.599z" />
      <path d="m285.34 110.43 57.854 234.47-232.02-66.599z" />
      <path d="m749.36 244.08 57.854 234.47-232.02-66.599z" />
      <path d="m401.09 579.24 57.854 234.47-232.02-66.599z" />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "tone"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m20.004 1000.7v-532.88l487.72-251.41v593.66l492.25-257.74v446.83z"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "node"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke-width="1.3333">
      <path
        d="m518.29 995.35c-12.887 0-25.686-3.3806-36.984-9.8859l-117.72-69.696c-17.583-9.833-9.0033-13.311-3.2041-15.314 23.444-8.2089 28.201-10.062 53.225-24.273 2.6303-1.5006 6.0728-0.97096 8.7649 0.61765l90.439 53.667c3.2659 1.7653 7.8999 1.7653 10.928 0l352.63-203.54c3.2659-1.8535 5.3843-5.6491 5.3843-9.5328v-406.91c0-4.0603-2.1184-7.6793-5.4549-9.7094l-352.49-203.37c-3.2659-1.9419-7.5909-1.9419-10.857 0l-352.42 203.37c-3.416 1.9419-5.5873 5.7373-5.5873 9.6211v406.91c0 3.8838 2.1184 7.591 5.4725 9.4446l96.564 55.785c52.431 26.215 84.472-4.6782 84.472-35.748v-401.62c0-5.7373 4.5016-10.151 10.239-10.151h44.663c5.5608 0 10.151 4.4134 10.151 10.151v401.79c0 69.908-38.131 110.07-104.42 110.07-20.39 0-36.454 0-81.294-22.067l-92.504-53.313c-22.861-13.24-36.984-37.955-36.984-64.347v-406.91c0-26.48 14.123-51.195 36.984-64.259l352.98-203.63c22.332-12.622 51.989-12.622 74.144 0l352.54 203.72c22.773 13.152 36.984 37.778 36.984 64.259v406.91c0 26.392-14.211 51.018-36.984 64.259l-352.54 203.63c-11.298 6.5318-24.097 9.8859-37.16 9.8859"
      />
      <path
        d="m627.22 714.96c-154.29 0-186.64-70.826-186.64-130.23 0-5.6491 4.5546-10.151 10.213-10.151h45.546c5.0312 0 9.2681 3.6631 10.062 8.6325 6.8849 46.411 27.363 69.819 120.66 69.819 74.321 0 105.92-16.788 105.92-56.2 0-22.685-9.0033-39.544-124.46-50.842-96.564-9.5329-156.23-30.805-156.23-108.04 0-71.143 60.022-113.51 160.56-113.51 112.89 0 168.86 39.191 175.92 123.4 0.2648 2.9128-0.79441 5.7373-2.7363 7.8558-1.9418 2.0302-4.6781 3.2659-7.5027 3.2659h-45.811c-4.7664 0-8.915-3.3542-9.886-7.9441-11.033-48.812-37.69-64.435-110.16-64.435-81.118 0-90.562 28.246-90.562 49.43 0 25.686 11.122 33.189 120.57 47.664 108.39 14.388 159.85 34.689 159.85 110.86 0 76.792-64.082 120.84-175.83 120.84"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "copy"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m986.32 456.29h-418.24v-423.18m418.24 423.18v421.27h-684.8v-838.34h267.73z"
      fill="none"
      stroke-linejoin="round"
      stroke-width="64"
    />
    <path
      d="m720.2 987.89v0h-684.8v-838.34h172.78"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="64"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "web"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke-width="64"
      ><path
        d="m777.14 508.57a268.57 460 0 0 1-268.57 460 268.57 460 0 0 1-268.57-460 268.57 460 0 0 1 268.57-460 268.57 460 0 0 1 268.57 460zm188.57-2.8571a460 460 0 0 1-460 460 460 460 0 0 1-460-460 460 460 0 0 1 460-460 460 460 0 0 1 460 460z"
        stop-color="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        style="paint-order:fill markers stroke"
      /><path d="m70.769 669.45h881.79m-885.14-310.46h885.14" /></g
    >
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "paperplane"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke-linecap="round" stroke-width="64">
      <path d="m39.396 493.54 947.08-383.35" />
      <path d="m668.56 768.2 317.91-658.02" />
      <path d="m39.396 493.54 213.57 89.36" />
      <path d="m252.96 582.9 733.51-472.71" />
      <path d="m404.18 653.41 582.3-543.23" />
      <path d="m404.18 653.41 264.39 114.79" />
      <path d="m252.96 582.9 39.516 331.83" />
      <path d="m404.18 653.41-111.7 261.31" />
      <path d="m292.48 914.73 209.84-216.03" />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "up"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m128.17 742.74 393.21-479.6 394.99 479.6"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="64"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "down"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m128.17 263.14 393.21 479.6 394.99-479.6"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="64"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "right"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m282.47 897.04 479.6-393.21-479.6-394.99"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="64"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "left"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m762.06 897.04-479.6-393.21 479.6-394.99"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="64"
    />
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else}
  <!-- else content here -->
{/if}

<style>
  svg {
    stroke: var(--clr-highlight);
    fill: var(--clr-highlight);
  }
</style>
