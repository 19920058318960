import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";

function fetchJSON(f) {
  return new Promise(async (resolve, reject) => {
    let content = await fetch(f);
    let json = await content.json();
    resolve(json);
  });
}

export const languages = [
  {
    id: "en",
    file: "/texts/en.json",
    label: "English",
  },
  {
    id: "fr",
    file: "/texts/fr.json",
    label: "Français",
  },
];

function setDefaultLocale() {
  // messy solution to have my selectbox in LocaleSwitcher
  // have an inital value that is within my languages object
  let locale = getLocaleFromNavigator();
  if (!languages.map((l) => l.id).includes("local")) {
    let locale_splite = locale.split("-");
    locale = locale_splite.length > 1 ? locale_splite[0] : locale;
  }
  locale =
    new URL(window.location.href).pathname.split("/")[1] === "en" ? "en" : "fr";
  init({
    fallbackLocale: "en",
    initialLocale: locale,
  });
}

export function sync_init() {
  setDefaultLocale();
}

export async function init_i18n() {
  for (let l of languages) {
    const f = await fetchJSON(l.file);
    addMessages(l.id, f);
  }

  setDefaultLocale();
  // let locale = getLocaleFromNavigator();
  // if (!languages.map((l) => l.id).includes("local")) {
  //   let locale_splite = locale.split("-");
  //   locale = locale_splite.length > 1 ? locale_splite[0] : locale;
  // }
  // init({
  //   fallbackLocale: "en",
  //   initialLocale: locale,
  // });
}
