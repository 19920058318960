<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.ts";
  // import ImageViewer from ".../components/ImageViewer.svelte";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<!-- <svelte:head>
  <title>BaMit</title>
  <meta
    name="description"
    content="Interface web au code de calcule BaM, outil de calage bayesien de modèle."
  />
</svelte:head> -->

<div class="content">
  <h3>Présentation:</h3>
  <p>
    BaMit! est une application web développée à la demande de chercheurs
    d'Inrae. Elle vise à offrir une interface graphique web intuitive et
    réactive pour le code de calcul
    <a href="https://github.com/BaM-tools/BaM" target="_blank">BaM</a>, un outil
    de calage bayesien de modèles.
    <!-- BaM étant codé en Fortan et configurable
    uniquement via des fichiers textes, une telle application permet de rendre
    son utilisation bien plus simple et agréable. -->
  </p>
  <p>
    Visitez le site web <a
      href="https://hydroapps.recover.inrae.fr/BaMit/"
      target="_blank"
      title="Application BaMit!"
    >
      https://hydroapps.recover.inrae.fr/BaMit/
    </a> pour tester l'application. Cliquez sur "Aide" en haut à droite pour suivre
    un petit guide et quelques explications.
  </p>
  <div class="imgae-container" bind:this={container}>
    <!-- <h2>Hello</h2> -->
  </div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/bamit/2022-05-26 12-47-57-2.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>En quelques mots:</h3>
  <!-- <p> -->
  <ul>
    <!-- <li>
      Prototype; un seul des modèles implémenté dans BaM a été interfacé, l'un
      des plus général où l'utilisateur est invité à définire lui même
      l'équation (ou les équations) de son modèle.
    </li> -->
    <li>
      Interface utilisateur <span> logique, clair et intuitive</span>.
    </li>
    <li>
      Interface <span>réactive</span>, c'est-à-dire qu'elle s'adapte en temps
      réel à toutes les entrées/intéractions de l'utilisateur.
    </li>
    <li>
      <span> Import / export </span> des projets (fichier .bam qui est un fichier
      JSON).
    </li>
    <li>
      <span> Import de jeux de données </span> (csv, ...) et visualisation de
      ceux-ci sous forme de <span> tableau</span>.
    </li>
    <li>
      <span> Graphiques </span>
      permettant de visualiser les principaux résultats.
    </li>
    <li>
      <span> Téléchargement </span>
      des fichiers de configuration de BaM, des résultats, individuellement ou sous
      forme d'archive.
    </li>
    <li>
      <span> Multi-langue</span>, avec actuellement l'anglais et le français;
      d'autres traductions peuvent aisément être ajoutées.
    </li>
  </ul>
  <!-- </p> -->
  <!-- <h3>Technologies employées</h3>
  <p>
    L’utilisation du package RBaM était une contrainte fixé au début du projet
    d’où l’utilisation du package Shiny de R qui permet de créer un serveur
    depuis R. Le package Shiny n’a cependant pas été utilisé pourla création de
    l’interface web en elle-même, elle a entièrement été faite en JavaScript,
    sans framework. Les quelques librairies javascript utilisées sont papaparse,
    marked, d3 et jzip.
  </p> -->
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
    /* position: relative; */
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
    /* background-color: red; */
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
