<script>
  import { _ } from "svelte-i18n";
  import { link } from "svelte-routing";
  import { blur } from "svelte/transition";
  import TrueIcon from "../../components/TrueIcon.svelte";
  import Icon from "../../components/Icon.svelte";

  $: skills = [
    { icon: "web", label: "HTML/SVG/CSS" },
    { icon: "js", label: "JavaScript" },
    { icon: "ts", label: "TypeScript" },

    { icon: "java", label: "Java" },
    { icon: "r", label: "R" },
    { icon: "python", label: "Python" },
    { icon: "qt", label: "pyQt" },

    { icon: "node", label: "Node.js" },
    { icon: "tauri", label: "Tauri" },
    {
      icon: "electron",
      label: "Electron.js",
    },
    { icon: "svelte", label: "Svelte" },
    // { icon: "d3", label: "D3.js", desc: $_("technologies.plots") },
    // { icon: "tone", label: "Tone.js", desc: $_("technologies.sound") },
    // { icon: "leaflet", label: "Leaflet", desc: $_("technologies.mapping") },
    // { icon: "three", label: "Three.js", desc: "3D" },
    // { icon: null, label: "Express.js" },
    // { icon: null, label: "Socket.io" },
    // { icon: "r", label: "R" },
    // { icon: null, label: "Shiny", desc: "site web avec R" },
    { icon: null, label: ". . . " },
  ];
</script>

<svelte:head>
  <title>{$_("title")}</title>
  <meta name="description" content={$_("description")} />
</svelte:head>

<div class="container" in:blur={{ duration: 500 }} out:blur={{ duration: 0 }}>
  <div class="presentation">
    <h3 hidden>{$_("introduction")}</h3>
    <div class="name">
      <span>Ivan</span>
      <span>Horner</span>
      <button
        on:click={() => {
          window.scrollTo({ top: 10000000, behavior: "smooth" });
        }}
        class="contact-btn"
      >
        <Icon id="paperplane" title={$_("contact_me")} />
        <span class="text">{$_("contact_me")}</span>
      </button>
    </div>
    <div class="subtitle">
      <span>{$_("subtitle")}</span>
    </div>
    <!-- <div class="details"> -->
    <div class="who">
      <span>{$_("who.agro")}</span>
      <div class="sep">/</div>
      <span>{$_("who.hydro")}</span>
      <div class="sep">/</div>
      <span>{$_("who.dev")}</span>
    </div>
    <div class="what">
      <span>{$_("what.app")}</span>
      <!-- <div class="sep">/</div> -->

      <span>
        {$_("what.type_job")}
      </span>
      <span>
        {$_("what.type_sci")}
      </span>
    </div>
    <!-- </div> -->
  </div>

  <div class="skills-wrapper">
    <h3>{$_("skills")}</h3>
    <div class="skills">
      {#each skills as skill, i}
        <div class="skill">
          {#if skill.icon}
            <TrueIcon id={skill.icon} size="15" />
          {/if}
          <span class="label">{skill.label}</span>
          {#if skill.desc}
            <span class="desc">{`(${skill.desc})`}</span>
          {/if}
          {#if i + 1 != skills.length}
            <span>,</span>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .contact-btn {
    background: none;
    border: 1px solid var(--clr-highlight);
    border-radius: 1.5rem / 50%;
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    max-height: 3rem;
    margin-left: 1rem;
  }
  .contact-btn:hover {
    background-color: var(--clr-highlight-dark);
  }
  .skills-wrapper {
    padding-bottom: 1rem;
  }
  .name > span {
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 1;
    color: var(--clr-highlight);
  }
  .name {
    display: flex;
    gap: 1rem;
    align-items: center;
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
  }
  .subtitle {
    margin-bottom: 0.5rem;
  }
  .subtitle > span {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
  }
  .who {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    font-size: 1rem;
    /* font-weight: 100; */
    line-height: 1.2;
    font-weight: 600;
  }
  .what {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.2;
    gap: 0.75rem;
    font-weight: 200;
  }
  .who > span,
  .what > span {
    padding: 0 0.125rem;
    /* text-align: center; */
  }
  .who > span {
    white-space: nowrap;
  }
  .who > .sep {
    color: var(--clr-highlight);
    padding: 0 0.125rem;
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
  }
  .presentation {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .presentation > h3 {
    padding-bottom: 0;
  }

  .skills {
    /* padding-top: 1rem; */
    display: flex;
    flex-wrap: wrap;
    gap: 0.125rem;
    font-weight: 200;
  }
  .skill {
    display: flex;
    align-items: center;
    gap: 0.125rem;
  }
  /* .skill * {
    font-size: 1rem;
  } */
  .skill > .desc {
    /* font-size: 0.8rem; */
    font-weight: 100;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-grow: 1;
  }

  @keyframes up-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(15%);
    }
  }

  @media (min-width: 600px) and (min-height: 800px) {
    .name > span {
      font-size: 5rem;
    }
    .subtitle > span {
      font-size: 2rem;
    }
    .subtitle {
      padding-bottom: 1rem;
    }
    .skills {
      flex-direction: column;
    }
  }
</style>
