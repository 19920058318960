function rescaler(value, source, target) {
    let p = (value - source[0]) / (source[1] - source[0]);
    return p * (target[1] - target[0]) + target[0];
}
export function rescale(values, source, target) {
    return !Array.isArray(values)
        ? rescaler(values, source, target)
        : values.map((v) => rescaler(v, source, target));
}
export function rescale01(values, target) {
    return rescale(values, [0, 1], target);
}
