<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.js";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>Explore2</title>
  <meta
    name="description"
    content="Site internet pour explorer des données du projet Explore2 à travers des graphiques et des musiques générés depuis les données."
  />
</svelte:head>

<div class="content">
  <h3>Présentation:</h3>
  <p>
    Le site web <a href="https://explore2enmusique.github.io/" target="_blank"
      >Explore2 <i>en musique</i></a
    >
    permet d'explorer les données issues du projet
    <b>Explore2</b> aux travers de vidéos présentant des graphiques et des
    musiques générés à partir de celles-ci. Il s'agit de données de débits
    simulés pour la periode 1976-2100 pour un échantillon de bassins versant
    français. Le projet <b>Explore2</b> met en lumière les changements actuels
    et futurs des régimes hydrologiques suivant
    <a href="https://explore2enmusique.github.io/narratifs" target="_blank"
      >différents narratifs</a
    > sur l'évolution des conditions climatiques.
  </p>
  <p>Le travail réalisé pour la conception de ce site web a nécéssité:</p>
  <ol>
    <li>
      la <a href="https://explore2enmusique.github.io/a_propos" target="_blank"
        >sonification des données</a
      >
      permettant la création des vidéos des graphiques animés synchronisé avec les
      musiques ainsi générée; un travail réalisé avec <b>R</b> à partir de codes
      initialement mis en place par
      <a href="https://globxblog.github.io/">Benjamin Renard</a>.
    </li>
    <li>
      la création d'un site web statique permettant d'acceder à ces vidéos pour
      différents bassins versant sur une carte intéractive.
    </li>
  </ol>
  <p>
    N'hésitez pas à <a href="https://explore2enmusique.github.io/"
      >aller faire un tour sur le site web</a
    >
    munies d'un casque audio ou de bonnes enceintes. D'ailleurs, si vous êtes
    interessés par d'autres projets de sonifications, allez également jeter un
    coup d'oeil au projet
    <a href="./hydrosounds"> Hydrological Soundscapes </a>.
  </p>
  <div class="imgae-container" bind:this={container}></div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/explore2/2024-07-24 12-48-11 - small.mp4
"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
    /* position: relative; */
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content b {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
