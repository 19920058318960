<script>
  import { onMount, onDestroy } from "svelte";
  import { blur } from "svelte/transition";
  import { link } from "svelte-routing";
  import Icon from "../components/Icon.svelte";
  import { projectStore, hasScrolledStore, headerBackground } from "../store";
  import { _, locale } from "svelte-i18n";
  export let id;
  $: project = $projectStore[id];

  onMount(async () => {
    $headerBackground = false;
  });
  onDestroy(() => {
    $headerBackground = true;
  });
</script>

<section>
  <div class="header" class:sticky={$hasScrolledStore}>
    <a class="back" href={$locale === "fr" ? "/" : "/en"} use:link>
      <Icon id="left" title={$_("back_to_main")} size="20" />
      <span> {$_("back")} </span>
    </a>
    {#if project}
      <h2>{project[$locale].name}</h2>
    {/if}

    <div class="links">
      {#if project && project.website}
        <a href={project.website} target="_blank" title={$_("visit_web_site")}>
          <Icon id="web" title={$_("web_site")} size="25" />
          <span> {$_("web_site")} </span>
        </a>
      {/if}
      {#if project && project.source}
        <a href={project.source} target="_blank" title={$_("see_source_code")}>
          <Icon id="github" title={$_("source_code")} size="25" />
          <span> {$_("source_code")}</span>
        </a>
      {/if}
    </div>
  </div>
  <div
    class="content"
    class:scrolled={$hasScrolledStore}
    in:blur={{ duration: 500 }}
    out:blur={{ duration: 0 }}
  >
    <slot />
  </div>
</section>

<style>
  section {
    max-width: 960px;
    min-height: calc(100vh - 4rem - 4rem);
    padding: 2.5rem 1rem 5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    --duration: 200ms;
  }
  .content {
    padding-top: 7.5rem;
    transition: padding-top var(--duration) ease;
    width: 100%;
  }
  .content.scrolled {
    padding-top: 5rem;
  }
  .header {
    position: fixed;

    inset: 5.5rem 0 auto 0;
    width: 100%;

    height: 7.5rem;

    margin-bottom: 5rem;

    background-color: rgba(0, 0, 0, 0.5);
    padding: 3rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: inset var(--duration) ease, height var(--duration) ease;
    max-width: 960px;
  }
  .header .back {
    inset: 0.75rem auto auto 1rem;
    transition: inset var(--duration) ease;
  }
  .header .links {
    position: absolute;
    inset: 0.75rem 1rem auto auto;
    transition: inset var(--duration) ease, transform var(--duration) ease;
    display: flex;
    gap: 1rem;
  }
  h2 {
    position: absolute;
    inset: auto auto 0.75rem 1rem;
    font-size: 2em;
    font-weight: 400;
    transition: inset var(--duration) ease, font-size var(--duration) ease;
    line-height: 1;
  }

  a.back {
    position: absolute;
  }
  a {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .header.sticky {
    position: fixed;
    inset: 0 0 auto 0;
    height: 5rem;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.5rem;
    padding-bottom: 0.25rem;
  }
  .header.sticky h2 {
    font-size: 1.25rem;
  }
  .header.sticky a.back {
    inset: 0 auto auto 9rem;
    height: 2.5rem;
  }
  .header.sticky .links {
    inset: 0.75rem 1rem auto auto;
    inset: 100% 1rem auto auto;
    transform: translateY(calc(-100% - 0.75rem));
  }
</style>
