<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.ts";
  // import ImageViewer from ".../components/ImageViewer.svelte";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>BaMit</title>
  <meta
    name="description"
    content="Interface web au code de calcule BaM, outil de calage bayesien de modèle."
  />
</svelte:head>

<div class="content">
  <h3>Overview:</h3>
  <p>
    BaMit! is web application developped for researchers of Inrae (National
    Research Institute for Agriculture, Food and the Environment). It is an
    intuitive and reactive graphical user interface for
    <a href="https://github.com/BaM-tools/BaM" target="_blank">BaM</a>, a
    software designed for the bayesian calibration of models.
  </p>
  <p>
    Go check it out at
    <a
      href="https://hydroapps.recover.inrae.fr/BaMit/"
      target="_blank"
      title="Application BaMit!"
    >
      https://hydroapps.recover.inrae.fr/BaMit/
    </a>
    . Click the Help button to have a quick start guide.
  </p>
  <div class="imgae-container" bind:this={container} />
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/bamit/2022-05-26 12-47-57-2.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>In a few words:</h3>
  <ul>
    <li>
      A <span> clear and intuitive </span> graphical user interface.
    </li>
    <li>
      It is <span>reactive</span>, meaning that it continuously adapts to all
      the user inputs and interactions.
    </li>
    <li>
      Projects can be <span> imported / exported </span> (as .bam files which are
      JSON files).
    </li>
    <li>
      <span>Datasets can be imported</span> (csv, ...) and explored in
      <span>tables</span> within the app.
    </li>
    <li>
      <span>Plots</span> make it possible to visualize the main results.
    </li>
    <li>
      BaM configuration files and calibration results can be <span
        >downloaded</span
      >
      (each file individually or all together as a .zip file).
    </li>
    <li>
      <span>Multi-languages</span> support with only french and english for now but
      with the possibility to easily add other languages.
    </li>
  </ul>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
    /* position: relative; */
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
    /* background-color: red; */
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
