<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.js";
  // import ImageViewer from ".../components/ImageViewer.svelte";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();

    await getBaratinageDownloadUrls();
  });

  async function getBaratinageDownloadUrls() {
    const reponse = await fetch(
      "https://api.github.com/repos/BaRatin-tools/BaRatinAGE/releases/latest"
    );
    const response_data = await reponse.json();
    const version = response_data.tag_name.replace("v", "");
    linux_link = `https://github.com/BaRatin-tools/BaRatinAGE/releases/latest/download/BaRatinAGE-${version}_Linux.tar.gz`;
    windows_link = `https://github.com/BaRatin-tools/BaRatinAGE/releases/latest/download/BaRatinAGE-${version}_Windows.zip`;
  }

  let windows_link;
  let linux_link;

  let container, videoElement;
</script>

<svelte:head>
  <title>BaRatinAGE v3</title>
  <meta
    name="description"
    content="Interface graphique pour l'établissement de courbe de tarage suivant une méthode d'estimation bayesienne."
  />
</svelte:head>

<div class="content">
  <h3>Présentation:</h3>
  <p>
    BaRatinAGE (BAyesian RATINg curve Advance Graphical Environment) est une
    interface graphique à une méthode bayésienne d'estimation des courbes de
    tarage (relations entre hauteur d’eau et débit dans les rivières) et des
    incertitudes associées. Le logiciel est actuellement utilisé dans le monde
    entier (traduit dans 27 langues), par les services opérationnels en charge
    du suivi des cours d'eau, des bureaux d'études, des chercheurs, des
    étudiants, etc...
  </p>
  <p>
    <span>BaRatinAGE v3</span> est une réécriture de BaRatinAGE v2 réalisé à la
    demande de chercheurs d'Inrae. Ce travail a été réalisé afin de prendre en
    compte les récents développements de la méthode bayésienne sous-jacente,
    <span>la méthode BaRatin</span>, de poser les fondations pour une
    généralisation de l’interface graphique à d'autres modèles de courbe de
    tarage et enfin, pour modernier le logiciel et améliorer de nombreux aspects
    ergonomiques.
  </p>
  <p>
    Si vous souhaitez plus d'informations sur la méthode BaRatin et son
    interface graphique BaRatinAGE rendez vous sur le site
    <a href="https://baratin-tools.github.io/fr/">
      https://baratin-tools.github.io/fr/
    </a>.
  </p>
  <p>
    BaRatinAGE v3 est gratuit et Open Source. Il peut être téléchargé depuis <a
      href="https://github.com/BaRatin-tools/BaRatinAGE/releases/latest"
    >
      Github. Une version
    </a> <a href={windows_link}>Windows</a> et
    <a href={linux_link}>Linux</a> sont actuellement disponibles.
  </p>
  <div class="image-container" bind:this={container}></div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/baratinage/2024-01-29 16-55-38.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>En quelques mots:</h3>
  <ul>
    <li>
      implémentation du package jbam, <span>API Java</span> permettant de configurer
      et contrôler l’exécutable BaM
    </li>
    <li>
      interface graphique <span>Swing</span>, avec une factorisation du code
      très poussée pour le rendre le plus générique possible; le but est de
      rendre l'<span>interface graphique facile à faire évoluer</span> avec de nouveau
      modèles de courbe de tarage et même des modèles qui sorte de ce cadre.
    </li>
    <li>
      interface graphique <span>réactive</span> : les actions des
      l’utilisateur·ice sont automatiquement répercutées dans le reste de
      l’interface graphique, gestion des problèmes de <span>synchronicité</span>
      entre configurations et résultats,
      <span>graphiques interactifs</span> pour présenter les résultats,
      <span>changement de langue</span> sans redémarrage
    </li>
    <li>
      <span>packaging sous forme d’exécutable stand-alone</span> (pas besoin d’avoir
      Java, pas besoin d’installation), sauvegarde/chargement de fichiers projets,
      importation de projets BaRatinAGE v2, icônes sur mesure,...
    </li>
  </ul>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
    /* position: relative; */
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .image-container {
    height: 600px;
    width: 100%;
    /* background-color: red; */
  }
  @media (min-width: 1600px) {
    .image-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
