<script>
  import { _ } from "svelte-i18n";
  import Landing from "./index/Landing.svelte";
  import Projects from "./index/Projects.svelte";
  import Contact from "./index/Contact.svelte";
  export let has_scrolled = true;
</script>

<section id="landing" class={has_scrolled ? "has-scrolled" : ""}>
  <h2 class="hidden">{$_("welcome")}</h2>
  <Landing />
</section>
<section id="projects">
  <h2>{$_("some_projects")}</h2>
  <Projects />
</section>
<section id="contact">
  <h2>{$_("contact_me")}</h2>
  <Contact />
</section>

<!-- </Router> -->
<style>
  section {
    max-width: 960px;
    /* min-height: 100vh; */
    padding: 2.5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
  }
  section#landing {
    min-height: calc(100vh - 100px);
  }

  section#contact {
    min-height: calc(100vh - 10rem);
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    display: flex;
    /* justify-content: stretch; */
    align-items: center;
    width: 100%;
    gap: 1rem;
  }
  h2::after {
    content: "";
    background-color: white;
    width: 100%;
    /* margin-top: 0.25rem; */
    /* margin-left: 0.25rem; */
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  h2.hidden {
    display: none;
  }
</style>
