<script>
  import { onMount } from "svelte";
  // import { setupMockup } from "./Mockup.ts";
  // import ImageViewer from "./components/ImageViewer.svelte";
  // import App from "./App.svelte";
  import { setupMockup } from "../../3d/Mockup.ts";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>Surcote</title>
  <meta
    name="description"
    content="Plugin QGIS permettant l'estimation de l'emprise d'une crue extrème à partir de l'emprise d'une crue connue."
  />
</svelte:head>

<div class="content">
  <h3>Présentation:</h3>
  <p>
    Création et mise en place de l'onglet pré-traitements du plugin <a
      href="https://qgis.org/"
      target="_blank"
      title="QGIS">QGIS</a
    > Surcote. Il s'agit d'un plugin visant à estimer l'emprise d'une zone innondable
    d'une crue extrème à partir d'une zone inondée dont on connait l'emprise. Ce
    plugin est issue de travaux de chercheurs d'Inrae et est principalement à destination
    des services de l'état et de bureaux d'étude.
  </p>
  <p>
    Ma contribution à ce plugin visait à offrir un certain nombre de traitements
    des données géographiques (couches vectorielles et rasters) utilisées en
    entrée d'un projet Surcote.
  </p>

  <div class="imgae-container" bind:this={container}>
    <!-- <h2>Hello</h2> -->
  </div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="./images/projects/surcote/2022-05-26 15-21-19-1.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>En quelques mots:</h3>
  <ul>
    <li>
      <span> Calculs/traitements </span> de données géographiques basiques et avancés.
    </li>
    <li>
      Interface utilisateur <span> intuitive </span> et <span> réactive</span>.
    </li>
    <li>
      Intégration transparente à QGIS avec <span> parallélisation </span> de
      certains calculs pour éviter tout "freeze" de QGIS, des
      <span> barres de progressions </span>
      et des
      <span> messages </span> d'information, d'avertissement et d'erreur.
    </li>
    <li>
      <ul>
        <span> Traitements implémentés:</span>
        <li>
          création de profil en travers de type ligne à partir de points (e.g.
          données de topographie fournis par un géomètre sur différentes lignes
          intersectant le lit de la rivière)
        </li>
        <li>
          Création d'un modèle numérique de terrain (MNT) virtuel (VRT) à partir
          d'un dossier contenant des dalles rasters (e.g. base de données Alti
          de l'Institut Géographique National)
        </li>
        <li>
          Création d'un MNT à partir d'un semis de points (e.g. données
          topographiques précises fournies par un géomètre).
        </li>
        <li>Fusion de MNT d'emprises et résolutions différentes.</li>
        <li>
          Découpage de données vectorielles et rasters selon une emprise définie
          par une couche vectorielle.
        </li>
        <li>
          Allongement automatique de profil en travers trop court (e.g. qui
          n'intersectent que partiellement la zone innondée connue).
        </li>
      </ul>
    </li>
  </ul>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
  }
  @media (min-width: 1600px) {
    .imgae-container {
      /* background-color: red; */
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  /* .container {
    height: 600px;
    width: 100%;
  } */
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
