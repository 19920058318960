import * as THREE from "three";
import { rescale } from "./utils";
import { texture_promise, laptop_promise } from "./ressources";
import { spring } from "svelte/motion";
export async function setupMockup(container, videoElement) {
    // ****************************************************************
    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    container.appendChild(renderer.domElement);
    // ****************************************************************
    // Camera setup
    const fov = 4;
    // const fov = 2.5;
    const camera = new THREE.PerspectiveCamera(fov, container.clientWidth / container.clientHeight, 0.001, 10000);
    // camera.position.set(7, 0.4, 0);
    camera.position.set(7.7, 0.4, -0.035);
    // camera.position.set(60, 3, 0);
    camera.rotateY(Math.PI / 2);
    camera.rotateX(-Math.PI / 128);
    // ****************************************************************
    // Scene setup
    const scene = new THREE.Scene();
    // ****************************************************************
    // loading model
    const gltf = await laptop_promise;
    // const keyboard_mesh = gltf.scene.children.find((m) => m.name === "Keyboard");
    // keyboard_mesh.material.roughness = 0.1;
    // const bottom_mesh = gltf.scene.children.find((m) => m.name === "Button");
    // const top_mesh = gltf.scene.children.find((m) => m.name === "Top");
    const env_map_meshes = gltf.scene.children.filter(c => !["Screen", "Top"].includes(c.name));
    scene.add(gltf.scene);
    let material_screen;
    const screen_mesh = gltf.scene.children.find((m) => m.name === "Screen");
    // const video = document.getElementById( 'video' );
    const tx = new THREE.VideoTexture(videoElement);
    tx.flipY = false;
    material_screen = new THREE.MeshStandardMaterial({
        emissiveMap: tx,
        emissiveIntensity: 1,
        emissive: new THREE.Color("rgb(255, 255, 255)")
    });
    screen_mesh.material = material_screen;
    screen_mesh.material.emissiveIntensity = 1;
    const dist = camera.position.clone().sub(gltf.scene.position);
    // const half_width = dist.length() * Math.tan((camera.fov * Math.PI) / 180 / 2);
    const container_width = container.clientWidth;
    const new_length = 0.55 / Math.tan((camera.fov * Math.PI) / 180 / 2) / 2;
    camera.position.setLength(new_length);
    // environment texture
    const environment_texture = await texture_promise;
    environment_texture.mapping = THREE.EquirectangularReflectionMapping;
    // keyboard_mesh.material.envMap = environment_texture;
    // keyboard_mesh.material.envMapIntensity = 0.2;
    env_map_meshes.forEach(m => {
        m.material.envMap = environment_texture;
        m.material.envMapIntensity = 0.2;
    });
    // render
    renderer.render(scene, camera);
    // ****************************************************************
    // resizing camera aspect ratio and renderer size
    function onResize() {
        const width = container.clientWidth;
        const aspect_ratio = 928 / 600;
        container.style.height = `${width / aspect_ratio}px`;
        const height = width / aspect_ratio;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        renderer.render(scene, camera);
    }
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(container);
    const movement_spring = spring({
        x: document.documentElement.clientWidth / 2,
        y: document.documentElement.clientHeight / 2,
    }, {
        stiffness: 0.05,
        damping: 0.85,
    });
    movement_spring.subscribe((o) => {
        mockupMotion(o.x, o.y);
    });
    document.addEventListener("mousemove", (e) => {
        movement_spring.set({
            x: e.clientX,
            y: e.clientY,
        });
    });
    function mockupMotion(x, y) {
        const p_x = rescale(x, [0, document.documentElement.clientWidth], [-1, 1]);
        const p_y = rescale(y, [0, document.documentElement.clientHeight], [-1, 1]);
        gltf.scene.rotation.set(0, (Math.PI / 64) * p_x, (Math.PI / 64) * p_y);
        renderer.render(scene, camera);
    }
    let frames_fps = 0;
    let frames_total = 0;
    let animating = true;
    function animate() {
        if (!animating)
            return;
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
        frames_fps++;
        frames_total++;
    }
    // ****************************************************************
    // fps monitoring and performance management
    let starting_fps;
    let fps = 0;
    let fps_monitoring;
    function startMonitoringFPS() {
        fps_monitoring = window.setInterval(() => {
            fps = frames_fps;
            if (!starting_fps)
                starting_fps = fps;
            frames_fps = 0;
        }, 1000);
    }
    // ****************************************************************
    // creating final object available to the user
    const obj = {
        render: function () {
            renderer.render(scene, camera);
        },
        startAnimation: function () {
            // initialize monitoring
            animating = true;
            animate();
            startMonitoringFPS();
        },
        stopAnimation: function () {
            clearInterval(fps_monitoring);
            fps = 0;
            frames_fps = 0;
            animating = false;
        },
    };
    return obj;
}
