<script>
  import { onMount } from "svelte";
  import { setupBackground } from "./background.ts";

  onMount(async () => {
    const obj = await setupBackground(
      background,
      document.documentElement,
      document,
      document.querySelector("main")
    );
    obj.startAnimation();
    obj.onResize();
    setInterval(() => {
      n = obj.getNumberOfBubbles();
      fps = obj.getFPS();
    }, 250);
  });

  let background;
  let n = 0;
  let fps = 0;
</script>

<div class="info">
  Il y a<span>{` ${n} `}</span>bulles (<span>{fps}</span>FPS)
</div>

<div class="background" bind:this={background} />

<style>
  .background {
    background-color: #001317;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    opacity: 1;
    animation: 5s linear 0s normal initial_opacity;
  }
  @keyframes initial_opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .info {
    position: fixed;
    inset: auto 1.25rem 0.25rem auto;
    padding: 0.1rem;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 0.6rem;
    font-weight: 100;
    z-index: 5;
  }
  .info > span {
    font-weight: 400;
    font-size: inherit;
  }
</style>
