<script>
  import { onMount, onDestroy } from "svelte";
  import Icon from "./Icon.svelte";
  import TrueIcon from "./TrueIcon.svelte";
  import { link } from "svelte-routing";
  import { _, locale } from "svelte-i18n";
  let techs = {
    js: "JavaScript",
    svelte: "Svelte",
    r: "R language",
    tone: "Tone.js",
    d3: "D3.js",
    leaflet: "Leaflet",
  };
  export let project;

  // onMount(() => {
  //   document.addEventListener("scroll", animateCardEntrance);
  // });
  // onDestroy(() => {
  //   document.removeEventListener("scroll", animateCardEntrance);
  // });
  // function animateCardEntrance() {
  //   let documentBbox = document.documentElement.getBoundingClientRect();
  //   let elementBbox = container.getBoundingClientRect();
  //   let y_top = -documentBbox.y;
  //   let y_bot = y_top + document.documentElement.clientHeight;
  //   let ye_top = elementBbox.y + y_top;
  //   let ye_bot = ye_top + elementBbox.height;

  //   if (ye_top < y_bot && ye_bot > y_top) {
  //     container.classList.add("onscreen");
  //   } else {
  //     container.classList.remove("onscreen");
  //   }
  // }
  let container;
  $: url = `/${$locale === "fr" ? "" : "en/"}${project.id}`;
</script>

<a class="more" href={url} use:link>
  <div class="container onscreen" bind:this={container}>
    <div class="info">
      <h3>{project[$locale].name}</h3>
      <p>{project[$locale].description}</p>
    </div>
    <a id="more" href={url} use:link>
      <Icon id="right" title={$_("know_more")} />
    </a>
    <div class="links-techs">
      <div class="links">
        {#if project.website}
          <a
            href={project.website}
            target="_blank"
            title={$_("visite_web_site")}
            on:click|stopPropagation
            class="underlined"
          >
            <Icon id="web" title={$_("web_site")} size="15" />
            {$_("web_site")}
          </a>
        {/if}
        {#if project.source}
          <a
            href={project.source}
            target="_blank"
            title={$_("see_source_code")}
            on:click|stopPropagation
            class="underlined"
          >
            <Icon id="github" title={$_("source_code")} size="15" />
            {$_("source_code")}
          </a>
        {/if}
      </div>
      <div class="techs">
        <span>{$_("techs")} </span>
        {#each project.techs as tech}
          <TrueIcon id={tech} title={techs[tech]} size="15" />
        {/each}
      </div>
    </div>
  </div>
</a>

<style>
  a.underlined {
    padding: 0.25rem 0.125rem;
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a.underlined:hover {
    border-bottom: 1px solid var(--clr-highlight);
    background-color: rgba(255, 255, 255, 0.1);
  }
  .container {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.75rem;
    padding-right: 3rem;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;

    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;

    font-weight: 200;
  }
  .onscreen {
    transform: translateX(0);
  }
  h3 {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
  }
  a {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.8rem;
  }
  a#more {
    position: absolute;
    inset: 1rem 0.75rem auto auto;
  }

  a.more {
    width: 100%;
    flex-grow: 1;
  }
  p {
    font-size: 0.9rem;
  }
  span {
    font-size: 0.8rem;
  }
  .links-techs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .links,
  .techs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  @media (min-width: 600px) {
    .container {
      padding-bottom: 5rem;
    }
    .links,
    .techs {
      position: absolute;
    }
    .links {
      inset: auto auto 1rem 1rem;
    }
    .techs {
      inset: auto 1rem 1rem auto;
    }
  }

  #more {
    animation: left-right 1s cubic-bezier(0.64, 0, 0.78, 0) infinite alternate;
    display: flex;
    align-items: center;
  }
  @keyframes left-right {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(10%);
    }
  }
</style>
