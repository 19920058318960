import * as THREE from "three";
import { rescale01 } from "../3d/utils";
import { sphere } from "../3d/ressources";
// import { HDRItexture } from "../store";
// let material: THREE.MeshPhysicalMaterial;
/**
 * Create the material used by all bubble. Needs to
 * be done asynchronously because of the HDR env map.
 */
// export async function initMaterial(): Promise<void> {
//   // const environment_texture = await new RGBELoader()
//   //   .setPath("images/")
//   //   .loadAsync("kloppenheim_02_1k.hdr");
//   // const environment_texture = await fetchTexture();
//   const environment_texture = await texture_promise;
//   environment_texture.mapping = THREE.EquirectangularReflectionMapping;
//   material = new THREE.MeshPhysicalMaterial({
//     color: "white",
//     metalness: 0,
//     roughness: 0,
//     ior: 1.5,
//     transmission: 1,
//     specularIntensity: 1,
//     specularColor: "white",
//     opacity: 0.5,
//     // opacity: 1,
//     side: THREE.DoubleSide,
//     transparent: true,
//     flatShading: false,
//     envMap: environment_texture,
//     envMapIntensity: 1,
//   });
// }
export default class Bubble {
    constructor(x, y, z, radius = 0, material) {
        // this.ready = false;q
        this.mesh = new THREE.Mesh(sphere, material);
        // Promise.all([material_promise]).then((values) => {
        //   this.ready = true;
        //   this.mesh = new THREE.Mesh(sphere, values[0]);
        // });
        // material_promise.then()
        // this.mesh =
        this.radius = radius;
        this.pos = new THREE.Vector3(x, y, z);
        this.vel = new THREE.Vector3(0, 0, 0.01);
        this.acc = new THREE.Vector3(0, 0, 0);
        this.max_acc = 1;
        this.max_vel = 1;
        this.date = new Date().getTime();
        this.update();
    }
    wander(strength = 1, randomness = 0.5) {
        // the wander point is the velocity vector with a specific length
        const wander_point = this.vel.clone().setLength(strength).add(this.pos);
        // the random point is a vector with a specific length
        const random_point = new THREE.Vector3(rescale01(Math.random(), [-1, 1]), rescale01(Math.random(), [-1, 1]), rescale01(Math.random(), [-1, 1])).setLength(randomness);
        // we update the wander point by adding the random point
        wander_point.add(random_point);
        // the steering force is the difference between the position and the wander point
        const steer = wander_point.clone().sub(this.pos);
        // we can now apply the steering force
        this.applyForce(steer);
    }
    seek(x, y, z) {
        const target = new THREE.Vector3(x, y, z);
        const steer = target.sub(this.pos);
        this.applyForce(steer);
    }
    seekUp(strength = 1000) {
        this.seek(this.pos.x, this.pos.y + strength, this.pos.z);
    }
    seekZ(strength = 1000) {
        this.seek(this.pos.x, this.pos.y, this.pos.z + strength);
    }
    flee(x, y, z, max_dist) {
        const target = new THREE.Vector3(x, y, z);
        const distance = target.distanceTo(this.pos);
        let strength = distance > max_dist ? 0 : 1 / (1 + distance * distance);
        // if (strength < 0.01) strength = 0;
        const steer = target.sub(this.pos).multiplyScalar(-1 * strength);
        this.applyForce(steer);
    }
    slowDown(strength) {
        const steer = this.vel.clone().multiplyScalar(-1);
        const length = steer.length();
        steer.setLength(length * length * strength);
        this.applyForce(steer);
    }
    applyForce(force) {
        this.acc.add(force);
    }
    update() {
        // if (!this.ready) return;
        this.acc.clampLength(0, this.max_acc);
        this.vel.add(this.acc);
        this.vel.clampLength(0, this.max_vel);
        this.pos.add(this.vel);
        this.acc = new THREE.Vector3(0, 0, 0);
        this.mesh.position.set(this.pos.x, this.pos.y, this.pos.z);
    }
}
