<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.ts";
  // import ImageViewer from "./components/ImageViewer.svelte";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>Hydrological Soundscapes</title>
  <meta
    name="description"
    content="Web app permettant d'exploration musicale/sonore de données hydrologiques (débits) dans le monde entier."
  />
</svelte:head>

<div class="content">
  <h3>Présentation:</h3>
  <p>
    Hydrological Soundscapes est une application web réalisée à la demande d'un
    chercheur d'Inrae qui permet de visualiser et surtout d'<span>écouter</span>
    les régimes hydrologiques partout dans le monde. Des données de débit sont utilisées
    pour générer des sons automatiquement: des notes d'un piano plus ou moins graves/aigues
    selon la valeur du débit moyen mensuel, un rythme plus rapide/lent selon le débit
    moyen annuel, une basse plus ou moins forte selon la fréquence des crues, etc.
  </p>
  <p>
    C'est une application très ludique et très facile d'accès que je vous
    encourage à tester. Equipez vous d'un bon casque audio ou de bonnes
    enceintes et rendez vous sur le site:
    <a
      href="https://hydrosoundscapes.recover.inrae.fr"
      target="_blank"
      title="Hydrological Soundscapes"
      >https://hydrosoundscapes.recover.inrae.fr</a
    >!
  </p>
  <p>
    Si vous souhaitez en apprendre d'avantage sur Hydrological Soundscapes, j'ai
    écrit un article pour le blog <em>Hydrological Science</em> de l'EGU
    (European Geoscience Union) que vous pouvez lire
    <a
      href="https://blogs.egu.eu/divisions/hs/2023/02/01/hydrological-soundscapes-listening-to-hydrological-regimes/"
      target="_blank"
      title="Article de Blog sur Hydrological Soundscapes"
    >
      ici
    </a>.
  </p>
  <div class="imgae-container" bind:this={container}>
    <!-- <h2>Hello</h2> -->
  </div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/hydrosounds/2022-05-26 12-10-52-3.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>En quelques mots:</h3>
  <!-- <p> -->
  <ul>
    <!-- <li>
      Prototype; un seul des modèles implémenté dans BaM a été interfacé, l'un
      des plus général où l'utilisateur est invité à définire lui même
      l'équation (ou les équations) de son modèle.
    </li> -->
    <li>
      Génération de <span>mélodies/sons</span> automatiquement à partir de données.
    </li>
    <li>
      <span>Synchronisation</span> entre sons et graphiques.
    </li>
    <li>
      Interface adaptée à <span> toute taille d'écran </span>: mobile, tablette,
      ordinateur.
    </li>

    <li>
      Interface <span>réactive</span>, c'est-à-dire qu'elle s'adapte en temps
      réel à toutes les entrées/intéractions de l'utilisateur.
    </li>
    <li>
      <span> Multi-langue</span>, avec actuellement l'anglais et le français;
      d'autres traductions peuvent aisément être ajoutées.
    </li>
    <li><span>Tutoriel</span> directement intégré à l'application.</li>
    <li>
      <span> Paramètrage </span> de plusieurs caractéristiques du son (tempo, gamme,
      batterie, etc.)
    </li>
    <li>Alliance entre <span> cartographie, sons et graphiques. </span></li>
  </ul>
  <!-- </p> -->
  <!-- <h3>Technologies employées</h3>
  <p>
    L’utilisation du package RBaM était une contrainte fixé au début du projet
    d’où l’utilisation du package Shiny de R qui permet de créer un serveur
    depuis R. Le package Shiny n’a cependant pas été utilisé pourla création de
    l’interface web en elle-même, elle a entièrement été faite en JavaScript,
    sans framework. Les quelques librairies javascript utilisées sont papaparse,
    marked, d3 et jzip.
  </p> -->
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
