import * as THREE from "three";
import Bubble from "./bubble";
// import { initMaterial } from "./bubble";
import { rescale01 } from "../3d/utils";
import { bubble_radius, material_promise, texture_promise, } from "../3d/ressources";
/**
 * Compute the half the height of what is visible through the camera
 * at a given a z depth
 * @param z z coordinate where you wan't the half visible height
 * @param camera perspective camera
 * @returns
 */
function computeHalfVisibleHeight(z, camera) {
    return (camera.position.z - z) * Math.tan((camera.fov * Math.PI) / 180 / 2);
}
/**
 * create a new bubble given x, y, z coordinates, a maximum acceleration
 * and a maximum velocity
 * @param x x coordinate
 * @param y y coordinate
 * @param z z coordinate
 * @param max_acc maximum acceleration
 * @param max_vel maximum velocity
 * @returns A new Bubble object
 */
function addNewBubble(x, y, z, mesh, max_acc = 0.01, max_vel = 0.15, radius = bubble_radius) {
    // const mesh = await mesh_promise;
    const bubble = new Bubble(x, y, z, radius, mesh);
    bubble.max_vel = max_vel;
    bubble.max_acc = max_acc;
    return bubble;
}
/**
 *
 * @param container container where to place the canvas
 * @param scroll_elem to retrieve clientHeight, scrollHeight and scrollTop
 * @param scroll_listener_elem to attach scroll listener
 * @param size_observer_elem to observe change in size
 * @returns
 */
export async function setupBackground(container, scroll_elem, scroll_listener_elem, size_observer_elem) {
    // ****************************************************************
    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    container.appendChild(renderer.domElement);
    // ****************************************************************
    // Camera setup
    const fov = 30;
    const distance = 1000;
    const camera = new THREE.PerspectiveCamera(fov, container.clientWidth / container.clientHeight, 0.1, 10000);
    camera.position.set(0, 0, distance);
    // ****************************************************************
    // Scene setup
    const scene = new THREE.Scene();
    const color = new THREE.Color("#001317");
    scene.background = color;
    scene.fog = new THREE.Fog(color, 500, 1500);
    // const light = new THREE.DirectionalLight("#37BAFC", 100);
    // console.log(
    //   getComputedStyle(document.documentElement).getPropertyValue(
    //     "--clr-highlight-light"
    //   )
    // );
    // const light = new THREE.DirectionalLight("#ffd8cc", 100);
    const light_color = getComputedStyle(document.documentElement)
        .getPropertyValue("--clr-highlight-light")
        .trim();
    console.log(`The light color is "${light_color}"`);
    if (!light_color.startsWith("#"))
        console.error("Change color format in global.css!");
    const light = new THREE.DirectionalLight(light_color, 100);
    light.position.set(-50, 50, 0);
    scene.add(light);
    // ****************************************************************
    // main options
    let options = {
        scroll_speed: 0.1,
        initial_bubble: 50,
        // bubble_z_range: [-900, 900], // z position range of where bubble may appear, the larger, the closer with 1000 being the max
        bubble_z_range: [-500, 450],
        bubble_x_range: [-10, 10], // x position range of where bubbles may appear
    };
    // total height is the height that can be scrolled by the user
    // scaled in a way that attribute "scrollTop" range between 0 and
    // total height. The options.scroll_speed affect the importance of the
    // camera movement related to the scroll: 0, no movement; 1, maximum movement
    let total_height = (scroll_elem.scrollHeight - scroll_elem.clientHeight) *
        options.scroll_speed;
    // ****************************************************************
    // initialize material
    // await initMaterial();
    // ****************************************************************
    // add initial bubble
    const bubble_material = material_promise;
    texture_promise.then((t) => {
        t.mapping = THREE.EquirectangularReflectionMapping;
        bubble_material.envMap = t;
    });
    let bubbles = [];
    for (let i = 0; i < options.initial_bubble; i++) {
        // let z = rescale01(Math.random(), options.bubble_z_range);
        // let x = rescale01(Math.random(), options.bubble_x_range);
        // let half_visible_height = computeHalfVisibleHeight(z, camera);
        // let y_max = -(half_visible_height + total_height) - bubble_radius;
        // let y_min = -half_visible_height - bubble_radius;
        // let y = rescale01(Math.random(), [y_min, y_max]);
        // let z = rescale01(Math.random(), options.bubble_z_range);
        let z = rescale01(Math.random(), [-500, 700]);
        let r = 50;
        let a = rescale01(Math.random(), [0, Math.PI * 2]);
        let x = r * Math.cos(a);
        let y = r * Math.sin(a);
        // let x = 0;
        // let y = -(total_height / 2);
        y = y - total_height / 2;
        let bubble = addNewBubble(x, y, z, bubble_material, 0.01, 0.15);
        bubbles.push(bubble);
        scene.add(bubble.mesh);
    }
    // function addBubble() {
    //   let z = rescale01(Math.random(), options.bubble_z_range);
    //   let x = rescale01(Math.random(), [-10, 10]);
    //   // let y = computeMaxHeight(z);
    //   let y = computeHalfVisibleHeight(z, camera);
    //   y = -(total_height + y) - bubble_radius;
    //   let bubble = new Bubble(x, y, z);
    //   bubble.max_acc = 0.01;
    //   bubble.max_vel = 0.15;
    //   bubbles.push(bubble);
    //   // bubble.mesh.material.envMap = environment_texture;
    //   // bubble.mesh.material.envMapIntensity = 1;
    //   scene.add(bubble.mesh);
    // }
    // ****************************************************************
    // resizing camera aspect ratio and renderer size
    function onResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        total_height = (scroll_elem.scrollHeight - scroll_elem.clientHeight) / 10;
        renderer.render(scene, camera);
    }
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(size_observer_elem);
    // ****************************************************************
    // moving camera when the user scrolls the foreground
    scroll_listener_elem.addEventListener("scroll", () => {
        let y = scroll_elem.scrollTop * options.scroll_speed;
        camera.position.set(0, -y, 1000);
        renderer.render(scene, camera);
    });
    // ****************************************************************
    // main animation loop
    let bubbles_appearance_frames = 120;
    let frames_fps = 0;
    let frames_total = 0;
    let animating = true;
    function animate() {
        if (!animating)
            return;
        requestAnimationFrame(animate);
        // Bubble behaviors / movement
        bubbles.forEach((m) => {
            if (m.pos.z > 800) {
                m.seekUp(100);
                // m.update();
                // return;
            }
            else if (m.pos.z > 600) {
                m.seekUp(1);
            }
            m.wander(2, 10);
            // m.seekRotate(Math.PI / 32, 0, -total_height / 2);
            // m.seekUp(10);
            m.seekZ(2);
            m.flee(0, -total_height / 2, m.pos.z, window.innerWidth / 4);
            m.slowDown(0.01);
            m.update();
        });
        // bubble removale
        bubbles = bubbles.filter((b) => {
            let rem_dist = computeHalfVisibleHeight(b.pos.z, camera) + bubble_radius;
            let to_remove = b.pos.y > rem_dist || b.pos.z > camera.position.z;
            if (to_remove)
                scene.remove(b.mesh);
            return !to_remove;
        });
        // add/remove bubble
        if (frames_total % bubbles_appearance_frames === 0) {
            if (fps >= 60 && bubbles.length <= 75) {
                console.log("Adding bubble");
                // let z = rescale01(Math.random(), options.bubble_z_range);
                let z = options.bubble_z_range[0];
                // let x = rescale01(Math.random(), [-10, 10]);
                // let y = computeHalfVisibleHeight(z, camera);
                // let z = 450;
                // let r = computeHalfVisibleHeight(0, camera);
                let r = 50;
                let a = rescale01(Math.random(), [0, Math.PI * 2]);
                let x = r * Math.cos(a);
                let y = r * Math.sin(a);
                // let x = 0;
                // let y = -(total_height / 2);
                y = y - total_height / 2;
                // y = -(total_height + y) - bubble_radius;
                let bubble = addNewBubble(x, y, z, bubble_material, 0.01, 0.15);
                bubbles.push(bubble);
                scene.add(bubble.mesh);
            }
            else if (fps != 0 && fps < 40 && bubbles.length > 0) {
                console.log("removing bubble", bubbles_appearance_frames);
                let i = Math.floor(Math.random() * bubbles.length);
                let b = bubbles.splice(i, 1)[0];
                scene.remove(b.mesh);
                bubbles_appearance_frames = bubbles_appearance_frames + 10;
            }
        }
        // update renderer
        renderer.render(scene, camera);
        // increment frame count
        frames_fps++;
        frames_total++;
    }
    // ****************************************************************
    // fps monitoring and performance management
    let starting_fps;
    let fps = 0;
    let fps_monitoring;
    function startMonitoringFPS() {
        fps_monitoring = window.setInterval(() => {
            fps = frames_fps;
            if (!starting_fps)
                starting_fps = fps;
            if (fps < starting_fps * 0.75) {
                // we have some performance issue:
                bubbles_appearance_frames = 300;
            }
            frames_fps = 0;
        }, 1000);
    }
    // fps_monitoring = setInterval(() => {
    //   fps = frames;
    //   frames = 0;
    // }, 1000);
    // ****************************************************************
    // creating final object available to the user
    const obj = {
        startAnimation: function () {
            // initialize monitoring
            animating = true;
            animate();
            startMonitoringFPS();
        },
        stopAnimation: function () {
            clearInterval(fps_monitoring);
            fps = 0;
            frames_fps = 0;
            animating = false;
        },
        getNumberOfBubbles: function () {
            return bubbles.length;
        },
        getFPS: function () {
            return fps;
        },
        onResize,
    };
    return obj;
}
