import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
/**
 * This constant defines the bubble radius. I don't wan't to make it
 * part of the options for now and I wan't all the bubble to share the
 * same geometry. Hopefully it positively affects performance.
 */
export const bubble_radius = 5;
// export const sphere = new THREE.SphereGeometry(bubble_radius, 64, 64);
export const sphere = new THREE.SphereGeometry(bubble_radius, 8, 16);
export const texture_promise = new RGBELoader()
    .setPath("/images/")
    // .loadAsync("artist_workshop_1k.hdr");
    .loadAsync("kloppenheim_02_1k.hdr");
// export const material_promise = new Promise(async (resolve, reject) => {
//   const environment_texture = await texture_promise;
//   environment_texture.mapping = THREE.EquirectangularReflectionMapping;
//   resolve(
//     new THREE.MeshPhysicalMaterial({
//       color: "white",
//       metalness: 0,
//       roughness: 0,
//       ior: 1.5,
//       transmission: 1,
//       specularIntensity: 1,
//       specularColor: "white",
//       opacity: 0.5,
//       // opacity: 1,
//       side: THREE.DoubleSide,
//       transparent: true,
//       flatShading: false,
//       envMap: environment_texture,
//       envMapIntensity: 1,
//     })
//   );
// });
export const material_promise = new THREE.MeshPhysicalMaterial({
    color: "white",
    metalness: 0,
    roughness: 0,
    ior: 1.5,
    transmission: 1,
    specularIntensity: 1,
    specularColor: "white",
    opacity: 0.5,
    // opacity: 1,
    side: THREE.DoubleSide,
    transparent: true,
    flatShading: false,
    // envMap: environment_texture,
    envMapIntensity: 1,
});
export const laptop_promise = new Promise(async (resolve, reject) => {
    const loader = new GLTFLoader();
    // const gltf = await loader.loadAsync("/3d/laptop_mockup_new_3.gltf");
    const gltf = await loader.loadAsync("/3d/laptop_mockup_3.gltf");
    console.log(gltf);
    resolve(gltf);
});
