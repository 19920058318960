<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.ts";
  // import ImageViewer from "./components/ImageViewer.svelte";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>Hydrological Soundscapes</title>
  <meta
    name="description"
    content="A web app allowing to explore a hydrological regime musically. Streamflow data from all around the world are used to automatically generate music and enable listening to differences in hydrological regimes."
  />
</svelte:head>

<div class="content">
  <h3>Overview:</h3>
  <p>
    Hydrological Soundscapes is a web application created for (and with)
    <a href="https://globxblog.inrae.fr/">Benjamin Renard</a>, a researcher at
    Inrae (National Research Institute for Agriculture, Food and the
    Environment) which allows visualizing and, more importantly,
    <span>listening to</span>
    hydrological regimes from all around the world. Streamflow data are used to automatically
    generate sounds/musics: notes from a piano are more or less high depending on
    the monthly average streamflow value, the music tempo is more or less fast depending
    on the overal yearly streamflow volumes, a bass plays loudly the mouths where
    the most floods occur, and so on...
  </p>
  <p>
    This is a very fun and easy to use app that I strongly encourage you check
    out. Equip yourself with a headset or good speakers and visit the web site:
    <a
      href="https://hydrosoundscapes.recover.inrae.fr"
      target="_blank"
      title="Hydrological Soundscapes"
      >https://hydrosoundscapes.recover.inrae.fr</a
    >!
  </p>
  <p>
    If you wish to learn more about Hydrological Soundscapes, I wrote an article
    for the Hydrological Science blog of the European Geoscience Union you can
    read <a
      href="https://blogs.egu.eu/divisions/hs/2023/02/01/hydrological-soundscapes-listening-to-hydrological-regimes/"
      target="_blank"
      title="Blog article about Hydrological Soundscapes"
    >
      here
    </a>.
  </p>
  <div class="imgae-container" bind:this={container}>
    <!-- <h2>Hello</h2> -->
  </div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/hydrosounds/2022-05-26 12-10-52-3.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>In a few words:</h3>
  <ul>
    <li>
      Automatically generating <span>melodies and sounds</span> from data.
    </li>
    <li>
      <span>Synchronisation</span> between sounds and plots
    </li>
    <li>
      Interface adapted to <span> any screen size </span> (mobile, tablette, computer)
    </li>

    <li>
      <span>Reactive</span> interface, i.e. it adapts to every user inputs/interactions.
    </li>
    <li>
      <span> Multi-language</span> support (french and english for now but other
      language can easily be added)
    </li>
    <li>An in-app <span>tutorial</span> to guide the user through the app</li>
    <li>
      Many <span> sound parameters can be tweaked </span> (tempo, musical scale,
      drums, etc.)
    </li>
    <li>
      Combination between <span> geographical mapping, sounds et plots. </span>
    </li>
  </ul>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
