<script>
  export let id;
  export let title = "";
  export let size = 25;
</script>

{#if id == "js"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m9.5308 9.6334h1004.5v1004.5h-1004.5zm896.72 531.09-72.934 46.704c-5.7832-9.6562-10.692-20.633-21.111-28.27-9.8481-7.2177-17.803-12.582-35.154-12.582-23.023 0-27.859 2.0507-37.366 10.814-9.8839 9.1105-13.021 19.635-13.021 35.868 0 12.175 7.767 22.661 15.108 29.56 7.5074 6.696 51.574 26.753 69.605 34.388 27.347 11.436 68.711 31.803 94.538 61.411 17.517 20.697 29.145 45.66 28.403 83.799-0.85068 43.738-14.602 70.228-39.126 94.779-24.358 24.349-61.645 36.524-111.86 36.524-23.69 0-47.985-0.82737-71.321-8.2178-46.264-14.652-83.655-44.122-104.8-86.305l74.739-43.729c14.533 20.233 15.751 27.539 34.632 39.964 20.401 11.771 34.704 17.882 55.391 17.882 21.021 0 40.429-5.013 51.606-13.535 11.178-8.5222 16.315-22.501 16.315-38.328 0-14.204-6.8447-24.559-16.624-32.27-29.314-18.34-50.775-27.358-84.838-41.568-61.817-21.524-101.57-69.377-103.35-128.79-0.75189-37.279 4.6433-67.115 38.592-99.284 24.829-23.527 60.513-33.516 105.56-33.516 64.331 1.4849 96.911 25.078 127.02 74.704zm-445.7-69.979 92.794 0.57369-0.86054 345.36c-0.0209 20.563-10.116 64.212-48.333 93.225-39.191 29.752-99.54 28.041-138.38 19.182-39.707-9.0574-70.274-36.684-94.034-78.941l75.805-46.591c12.03 20 17.701 32.125 31.542 40.949 11.19 7.1346 39.197 13.044 61.507-0.17287 15.867-9.3992 19.372-30.74 20.124-50.44z"
        fill="#efd81d"
        fill-rule="evenodd"
        stop-color="#000000"
        style="paint-order:fill markers stroke"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "r"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g
        transform="matrix(1.373 0 0 1.3729 15.61 126.31)"
        fill-rule="evenodd"
        stroke-width=".72836"
      >
        <path
          d="m361.45 485.94c-199.12 0-360.55-108.11-360.55-241.47 0-133.36 161.42-241.47 360.55-241.47 199.12 0 360.55 108.11 360.55 241.47 0 133.36-161.42 241.47-360.55 241.47zm55.188-388.53c-151.35 0-274.05 73.908-274.05 165.08s122.7 165.08 274.05 165.08c151.35 0 263.05-50.529 263.05-165.08 0-114.51-111.7-165.08-263.05-165.08z"
          fill="#b4b5bb"
        />
        <path
          d="m550 377s21.822 6.585 34.5 13c4.399 2.226 12.01 6.668 17.5 12.5 5.378 5.712 8 11.5 8 11.5l86 145-139 0.062-65-122.06s-13.31-22.869-21.5-29.5c-6.832-5.531-9.745-7.5-16.5-7.5h-33.026l0.026 158.97-123 0.052v-406.09h247s112.5 2.029 112.5 109.06-107.5 115-107.5 115zm-53.5-135.98-74.463-0.048-0.037 69.05 74.5-0.024s34.5-0.107 34.5-35.125c0-35.722-34.5-33.853-34.5-33.853z"
          fill="#1b67bb"
          stroke-width=".52002"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "tauri"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layer1">
      <path
        d="m 675.03117,366.45814 c 0,51.59078 -41.8701,93.41353 -93.52047,93.41353 -51.64959,0 -93.5197,-41.82275 -93.5197,-93.41353 0,-51.59078 41.87011,-93.41352 93.5197,-93.41352 51.65037,0 93.52047,41.82274 93.52047,93.41352 z"
        fill="#ffc131"
        id="path12"
        style="stroke-width:7.63844"
      />
      <ellipse
        cx="-424.22592"
        cy="-633.96021"
        rx="93.52047"
        ry="93.413536"
        transform="scale(-1)"
        fill="#24c8db"
        id="ellipse14"
        style="stroke-width:7.63844"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m 775.33458,666.01024 c -37.74,24.36356 -79.55741,41.27584 -123.19657,50.07149 8.93315,-25.72702 13.78586,-53.35831 13.78586,-82.12162 0,-10.43335 -0.63859,-20.71698 -1.8783,-30.815 18.77528,-6.53239 36.80047,-15.30284 53.66615,-26.19068 40.58379,-26.19909 72.73093,-63.54647 92.57789,-107.55458 19.84771,-44.0081 26.55198,-92.80397 19.30462,-140.52512 -7.24582,-47.72115 -28.13313,-92.33728 -60.15194,-128.48772 -32.01805,-36.15044 -73.80643,-62.29682 -120.34209,-75.29821 -46.53644,-13.00138 -95.84103,-12.30477 -141.99019,2.00739 -46.14916,14.31214 -87.17904,41.62949 -118.16054,78.67057 -3.96818,4.745 -7.74997,9.62291 -11.34003,14.62304 -47.809,5.30565 -94.11399,18.82111 -136.93584,39.72828 13.93863,-44.57105 36.56827,-86.18069 66.80808,-122.33495 C 351.57076,85.070252 409.95898,46.195256 475.63276,25.828426 541.30654,5.461619 611.47019,4.469516 677.6947,22.971344 c 66.2245,18.501828 125.69205,55.710573 171.25381,107.155006 45.57093,51.4449 75.2921,114.93713 85.60399,182.8482 10.31189,67.91031 0.77148,137.35137 -27.47547,199.9774 -28.23931,62.62604 -73.98592,115.7743 -131.74245,153.05829 z m -530.40866,-340.8455 87.53728,10.73964 c 2.08377,-16.9474 5.88542,-33.55413 11.29954,-49.5811 -34.71899,7.95849 -68.03634,21.05917 -98.83682,38.84146 z"
        fill="#ffc131"
        id="path16"
        style="stroke-width:7.63844"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m 229.79799,334.40802 c 38.02339,-24.54689 80.18605,-41.52944 124.18041,-50.26781 -9.94602,26.97821 -15.37618,56.13718 -15.37618,86.56414 0,9.10043 0.4858,18.08781 1.43297,26.93772 -18.39719,6.4919 -36.06414,15.13709 -52.61434,25.82174 -40.5838,26.19908 -72.73093,63.54647 -92.57789,107.55457 -19.84772,44.00735 -26.55121,92.80399 -19.30462,140.52515 7.24582,47.72115 28.13313,92.33726 60.15118,128.48847 32.01881,36.15274 73.80642,62.29912 120.34285,75.29974 46.53643,13.00063 95.84103,12.30552 141.99019,-2.00891 46.14916,-14.31444 87.17904,-41.6295 118.16055,-78.66829 3.98803,-4.77402 7.78738,-9.6779 11.39425,-14.70399 47.84872,-5.3851 94.16974,-18.99221 136.99084,-40.00098 -13.9287,44.70625 -36.59805,86.43505 -66.91732,122.68708 -44.08907,52.71287 -102.47806,91.58489 -168.15108,111.95661 -65.67377,20.36409 -135.83818,21.35709 -202.06193,2.85679 C 261.21337,958.94211 201.74582,921.73526 156.181,870.29037 110.61641,818.84549 80.892414,755.35478 70.580749,687.4437 60.269161,619.53262 69.808427,550.09233 98.05255,487.4663 126.29667,424.8395 172.04375,371.692 229.79799,334.40802 Z m 529.90757,340.78362 h -7.6e-4 c -0.75849,0.43844 -1.51852,0.87384 -2.28007,1.30694 0.76155,-0.4331 1.52234,-0.8685 2.28083,-1.30694 z m -91.12734,37.01817 c -2.38014,0.60191 -4.76562,1.17938 -7.15646,1.73164 0,7.6e-4 -7.6e-4,7.6e-4 -7.6e-4,7.6e-4 2.39236,-0.55302 4.77861,-1.13126 7.15722,-1.7324 z"
        fill="#24c8db"
        id="path18"
        style="stroke-width:7.63844"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "svelte"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        id="front"
        d="m444.86 888.31a181.14 181.14 0 0 1-194.33-72.089 167.25 167.25 0 0 1-28.573-126.7 148.55 148.55 0 0 1 5.4176-21.059l4.2816-13.107 11.709 8.7381a295.17 295.17 0 0 0 88.866 44.215l8.7381 2.534-0.78642 8.7381a51.555 51.555 0 0 0 9.5245 33.642 54.613 54.613 0 0 0 58.545 21.67 51.118 51.118 0 0 0 13.981-6.1167l227.54-145.23a47.36 47.36 0 0 0 21.408-31.807 50.419 50.419 0 0 0-8.7381-38.185 54.613 54.613 0 0 0-58.545-21.67 49.982 49.982 0 0 0-13.981 6.1166l-87.381 55.487a166.9 166.9 0 0 1-46.224 20.272 181.05 181.05 0 0 1-193.99-72.002 167.42 167.42 0 0 1-28.399-126.7 157.29 157.29 0 0 1 71.041-105.38l228.24-145.23a166.46 166.46 0 0 1 45.962-20.272 180.97 180.97 0 0 1 194.25 72.089 167.25 167.25 0 0 1 28.661 126.7 176.07 176.07 0 0 1-5.4176 21.234l-4.369 13.107-11.622-8.7381a295.17 295.17 0 0 0-89.128-44.564l-8.7381-2.534 0.78642-8.7381a51.205 51.205 0 0 0-9.2624-33.904 54.438 54.438 0 0 0-58.196-21.059 49.982 49.982 0 0 0-13.981 6.1166l-227.98 144.96a47.622 47.622 0 0 0-21.408 31.719 51.03 51.03 0 0 0 8.7381 38.273 54.613 54.613 0 0 0 58.108 21.67 52.428 52.428 0 0 0 13.981-6.1167l87.381-55.399a162.62 162.62 0 0 1 46.312-20.36 180.88 180.88 0 0 1 194.25 72.002 167.42 167.42 0 0 1 28.661 126.7 157.29 157.29 0 0 1-71.041 105.38l-227.98 145.23a167.6 167.6 0 0 1-46.312 20.36m422.4-731.99c-90.876-130.02-270.36-168.56-400.12-85.895l-227.89 145.23a261.27 261.27 0 0 0-118.14 174.76 275.51 275.51 0 0 0 27.088 176.86 261.62 261.62 0 0 0-39.059 97.692 278.39 278.39 0 0 0 47.622 210.76c90.876 130.02 270.36 168.56 400.12 85.895l227.89-144.62a262.14 262.14 0 0 0 118.14-174.76 275.42 275.42 0 0 0-27.175-176.77 263.28 263.28 0 0 0 39.147-97.692 278.74 278.74 0 0 0-47.622-210.76"
        fill="#f23b00"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "d3"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g
        transform="matrix(10.332 0 0 10.336 -5140.8 -6718.9)"
        fill="#e48546"
        stroke="none"
      >
        <path
          d="m499.58 655.24v19.951c7.7162 0 16.996-0.19807 25.254 6.8917 4.3597 3.7427 8.2703 9.1522 8.2703 18.488 0 7.5378-3.2192 15.095-8.886 19.54-7.3709 5.7815-15.131 6.2189-24.512 6.2189v20.014c13.5 0 31.397-2.4646 42.682-15.805 5.8046-6.8614 10.603-16.043 10.603-30.409 0-14.431-6.1994-26.137-15.731-33.642-10.011-7.882-19.426-11.247-37.681-11.247z"
        />
        <path
          d="m535.67 655.01c10.259 7.1973 13.605 10.417 18.625 19.951l14.016-0.0631c3.8848 0 6.9132 3.0305 6.9132 7.292 0 4.3563-2.8035 8.3653-6.9132 8.3653h-8.7126c1.7993 7.4814 1.4521 12.438 0 19.761h8.6494c3.1574 0 7.0711 2.557 7.0711 7.7655 0 4.2616-3.4297 7.8918-7.0711 7.8918h-14.079c-3.4093 7.6708-9.5018 14.584-18.498 19.887h33.461c8.0502 0 16.858-4.8621 21.077-12.169 4.0669-6.4449 8.523-20.756-1.5682-33.099 0 0 5.0946-6.8617 6.3881-12.776 1.5107-6.9078-0.92312-16.303-5.4603-22.45-5.5546-6.8386-11.181-10.211-23.139-10.211-10.313 0-21.234-0.16415-30.759-0.14576z"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "leaflet"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m76.847 454.06s-3.9322 18.585-7.9314 46.916l313.96 468.02-14.697 16.394-299.33-483.93c-2.5712 18.287-5.1581 40.51-6.7469 64.684-4.0755 62.02-8.0107 173.8 43.078 292.8 51.089 118.99 121.83 153.28 190.43 157.88 52.399 3.5321 79.667-29.659 99.413-56.572 19.747-26.917 64.97-25.691 75.401-99.603 10.446-73.911-21.852-150.78-95.841-236.92-73.989-86.128-286.8-157.02-297.64-169.67z"
        fill="#7bb640"
      />
      <path
        d="m878.17 5.7883c-14.798 23.987-352.23 197.98-453.51 361.31-101.27 163.33-130.17 291.95-93.453 412.84 36.708 120.88 106.89 115.43 146.89 155.29 40.007 39.881 94.283 88.268 180.97 68.73 113.51-25.593 222.89-101.35 277.3-313.67 54.407-212.32 18.733-398.25-4.2642-500.9-22.983-102.65-53.919-183.61-53.919-183.61zm-5.7424 35.848-385.17 901.76-24.012-24.107z"
        fill="#acd65c"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "three"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#049ef4" stroke-linejoin="round" stroke-width="64">
      <path d="m282.97 986.78-233.13-943.69 934.54 269.04z" />
      <path d="m516.95 177.64 116.48 471.99-467.01-134.56z" />
      <path d="m401.07 579.08-57.854-234.47 232.02 66.599z" />
      <path d="m285.34 110.43 57.854 234.47-232.02-66.599z" />
      <path d="m749.36 244.08 57.854 234.47-232.02-66.599z" />
      <path d="m401.09 579.24 57.854 234.47-232.02-66.599z" />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "tone"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m20.004 23.204h979.97l2e-5 975.96-979.97 1.5467z"
        fill="#e82fc9"
        fill-rule="evenodd"
        stop-color="#000000"
        style="paint-order:fill markers stroke"
      />
      <path
        d="m20.004 1000.7v-532.88l487.72-251.41v593.66l492.25-257.74v446.83z"
        fill="#1ecfb4"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "node"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#68a063" stroke-width="1.3333">
      <path
        d="m518.29 995.35c-12.887 0-25.686-3.3806-36.984-9.8859l-117.72-69.696c-17.583-9.833-9.0033-13.311-3.2041-15.314 23.444-8.2089 28.201-10.062 53.225-24.273 2.6303-1.5006 6.0728-0.97096 8.7649 0.61765l90.439 53.667c3.2659 1.7653 7.8999 1.7653 10.928 0l352.63-203.54c3.2659-1.8535 5.3843-5.6491 5.3843-9.5328v-406.91c0-4.0603-2.1184-7.6793-5.4549-9.7094l-352.49-203.37c-3.2659-1.9419-7.5909-1.9419-10.857 0l-352.42 203.37c-3.416 1.9419-5.5873 5.7373-5.5873 9.6211v406.91c0 3.8838 2.1184 7.591 5.4725 9.4446l96.564 55.785c52.431 26.215 84.472-4.6782 84.472-35.748v-401.62c0-5.7373 4.5016-10.151 10.239-10.151h44.663c5.5608 0 10.151 4.4134 10.151 10.151v401.79c0 69.908-38.131 110.07-104.42 110.07-20.39 0-36.454 0-81.294-22.067l-92.504-53.313c-22.861-13.24-36.984-37.955-36.984-64.347v-406.91c0-26.48 14.123-51.195 36.984-64.259l352.98-203.63c22.332-12.622 51.989-12.622 74.144 0l352.54 203.72c22.773 13.152 36.984 37.778 36.984 64.259v406.91c0 26.392-14.211 51.018-36.984 64.259l-352.54 203.63c-11.298 6.5318-24.097 9.8859-37.16 9.8859"
      />
      <path
        d="m627.22 714.96c-154.29 0-186.64-70.826-186.64-130.23 0-5.6491 4.5546-10.151 10.213-10.151h45.546c5.0312 0 9.2681 3.6631 10.062 8.6325 6.8849 46.411 27.363 69.819 120.66 69.819 74.321 0 105.92-16.788 105.92-56.2 0-22.685-9.0033-39.544-124.46-50.842-96.564-9.5329-156.23-30.805-156.23-108.04 0-71.143 60.022-113.51 160.56-113.51 112.89 0 168.86 39.191 175.92 123.4 0.2648 2.9128-0.79441 5.7373-2.7363 7.8558-1.9418 2.0302-4.6781 3.2659-7.5027 3.2659h-45.811c-4.7664 0-8.915-3.3542-9.886-7.9441-11.033-48.812-37.69-64.435-110.16-64.435-81.118 0-90.562 28.246-90.562 49.43 0 25.686 11.122 33.189 120.57 47.664 108.39 14.388 159.85 34.689 159.85 110.86 0 76.792-64.082 120.84-175.83 120.84"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "ts"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m9.5308 9.6334h1004.5v1004.5h-1004.5zm896.72 531.09-72.934 46.704c-5.7832-9.6562-10.692-20.633-21.111-28.27-9.8481-7.2177-17.803-12.582-35.154-12.582-23.023 0-27.859 2.0507-37.366 10.814-9.8839 9.1105-13.021 19.635-13.021 35.868 0 12.175 7.767 22.661 15.108 29.56 7.5074 6.696 51.574 26.753 69.605 34.388 27.347 11.436 68.711 31.803 94.538 61.411 17.517 20.697 29.145 45.66 28.403 83.799-0.85068 43.738-14.602 70.228-39.126 94.779-24.358 24.349-61.645 36.524-111.86 36.524-23.69 0-47.985-0.82737-71.321-8.2178-46.264-14.652-83.655-44.122-104.8-86.305l74.739-43.729c14.533 20.233 15.751 27.539 34.632 39.964 20.401 11.771 34.704 17.882 55.391 17.882 21.021 0 40.429-5.013 51.606-13.535 11.178-8.5222 16.315-22.501 16.315-38.328 0-14.204-6.8447-24.559-16.624-32.27-29.314-18.34-50.775-27.358-84.838-41.568-61.817-21.524-101.57-69.377-103.35-128.79-0.75189-37.279 4.6433-67.115 38.592-99.284 24.829-23.527 60.513-33.516 105.56-33.516 64.331 1.4849 96.911 25.078 127.02 74.704zm-445.7-69.979 92.794 0.57369-0.86054 345.36c-0.0209 20.563-10.116 64.212-48.333 93.225-39.191 29.752-99.54 28.041-138.38 19.182-39.707-9.0574-70.274-36.684-94.034-78.941l75.805-46.591c12.03 20 17.701 32.125 31.542 40.949 11.19 7.1346 39.197 13.044 61.507-0.17287 15.867-9.3992 19.372-30.74 20.124-50.44z"
        display="none"
        fill="#efd81d"
        fill-rule="evenodd"
        stop-color="#000000"
        style="paint-order:fill markers stroke"
      />
      <g
        transform="translate(-59.312 55.076)"
        style="shape-inside:url(#rect837);white-space:pre"
        aria-label="TS"
      >
        <path
          d="m821.51 877.46q-44 0-87.333-15.333-42.667-16-78-47.333l56.667-67.333q24.667 20 54 32.667 29.333 12.667 61.333 12.667 32 0 46.667-10.667 14.667-10.667 14.667-27.333 0-11.333-4.6667-18.667-4.6667-8-13.333-14-8.6667-6-21.333-11.333-12.667-5.3333-28-12l-56-24q-18-6.6667-35.333-17.333-16.667-10.667-29.333-24.667-12.667-14.667-20.667-33.333-7.3333-19.333-7.3333-43.333 0-26.667 11.333-50 12-24 32.667-41.333 21.333-18 50.667-28 29.333-10 64.667-10 40 0 77.333 14.667 37.333 14.667 67.333 42.667l-50 62q-22.667-16.667-45.333-25.333-22.667-9.3333-54.667-9.3333-24.667 0-40 10-14.667 10-14.667 29.333t19.333 30q20 10 52 21.333l52.667 22.667q43.333 16 68 44 25.333 28 25.333 74 0 27.333-11.333 52-11.333 24-32.667 42.667-21.333 18-52.667 29.333-31.333 10.667-72 10.667zm-423-8v-352h-130v-82.667h358.67v82.667h-130v352zm-329.67-914.9h1004.5v1004.5h-1004.5z"
          fill="#007acc"
          fill-rule="evenodd"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "python"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="linearGradient9521"
        x1="55.549"
        x2="110.15"
        y1="77.07"
        y2="131.85"
        gradientTransform="matrix(.11263 0 0 .11263 37.468 56.688)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#387eb8" offset="0" />
        <stop stop-color="#366994" offset="1" />
      </linearGradient>
      <linearGradient
        id="linearGradient11307"
        x1="89.137"
        x2="147.78"
        y1="111.92"
        y2="168.1"
        gradientTransform="matrix(.11263 0 0 .11263 37.468 56.688)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#ffe052" offset="0" />
        <stop stop-color="#ffc331" offset="1" />
      </linearGradient>
    </defs>
    <g>
      <g
        transform="matrix(8.8783 0 0 8.8783 -377.59 -570.76)"
        stroke-width=".11263"
      >
        <path
          d="m99.75 67.469c-28.032 2e-6 -26.281 12.156-26.281 12.156l0.03125 12.594h26.75v3.7812h-37.375s-17.938-2.0343-17.938 26.25c-2e-6 28.284 15.656 27.281 15.656 27.281h9.3438v-13.125s-0.50365-15.656 15.406-15.656h26.531s14.906 0.24096 14.906-14.406v-24.219c0-2e-6 2.2632-14.656-27.031-14.656zm-14.75 8.4688c2.6614-2e-6 4.8125 2.1511 4.8125 4.8125 2e-6 2.6614-2.1511 4.8125-4.8125 4.8125-2.6614 2e-6 -4.8125-2.1511-4.8125-4.8125-2e-6 -2.6614 2.1511-4.8125 4.8125-4.8125z"
          color="#000000"
          fill="url(#linearGradient9521)"
        />
        <path
          d="m100.55 177.31c28.032 0 26.281-12.156 26.281-12.156l-0.0312-12.594h-26.75v-3.7812h37.375s17.938 2.0343 17.938-26.25c1e-5 -28.284-15.656-27.281-15.656-27.281h-9.3438v13.125s0.50366 15.656-15.406 15.656h-26.531s-14.906-0.24096-14.906 14.406v24.219s-2.2632 14.656 27.031 14.656zm14.75-8.4688c-2.6614 0-4.8125-2.1511-4.8125-4.8125s2.1511-4.8125 4.8125-4.8125 4.8125 2.1511 4.8125 4.8125c1e-5 2.6614-2.1511 4.8125-4.8125 4.8125z"
          color="#000000"
          fill="url(#linearGradient11307)"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "qt"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0,52)">
      <path
        d="m145.08 157.68-140.78 140.78v604.41h869.97l145.84-145.84v-599.35zm228.82 121.21c67.076 0 114.12 19.165 141.12 57.494 27.004 37.893 40.506 96.475 40.506 175.75 0 52.267-5.6619 94.515-16.986 126.75-10.889 32.231-28.964 56.84-54.227 73.826l54.879 88.201-67.293 31.359-58.146-95.387c-8.2756 2.6133-21.56 3.9199-39.854 3.9199-67.947 0-115.42-18.294-142.43-54.881-26.569-36.587-39.854-94.297-39.854-173.13 0-79.271 13.501-138.07 40.506-176.4 27.44-38.329 74.698-57.494 141.77-57.494zm272.44 33.32h70.561v94.732h90.158v60.76h-90.158v143.73c0 26.569 1.9589 44.209 5.8789 52.92 3.92 8.7111 13.937 13.066 30.053 13.066l53.574-1.9609 3.2676 56.84c-29.182 5.6622-51.396 8.4942-66.641 8.4942-37.022 0-62.502-8.4938-76.439-25.48-13.502-16.987-20.254-48.999-20.254-96.039v-151.57h-41.814v-60.76h41.814zm-272.44 30.053c-40.942 0-69.035 13.501-84.279 40.506-15.244 27.004-22.867 70.126-22.867 129.36s7.404 101.7 22.213 127.4c14.809 25.262 43.12 37.893 84.934 37.893 41.813 0 69.908-12.414 84.281-37.24 14.809-25.262 22.213-67.729 22.213-127.4s-7.6227-103.01-22.867-130.01c-14.809-27.004-42.685-40.506-83.627-40.506z"
        fill="#30ce4c"
        fill-rule="evenodd"
        stop-color="#000000"
        style="paint-order:fill markers stroke"
      />
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "web"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke-width="64"
      ><path
        d="m777.14 508.57a268.57 460 0 0 1-268.57 460 268.57 460 0 0 1-268.57-460 268.57 460 0 0 1 268.57-460 268.57 460 0 0 1 268.57 460zm188.57-2.8571a460 460 0 0 1-460 460 460 460 0 0 1-460-460 460 460 0 0 1 460-460 460 460 0 0 1 460 460z"
        stop-color="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        style="paint-order:fill markers stroke"
      /><path d="m70.769 669.45h881.79m-885.14-310.46h885.14" /></g
    >
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "electron"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle
        cx="512.86"
        cy="515.71"
        r="486.57"
        fill="#2b2e3a"
        stroke="transparent"
      />
      <g
        transform="matrix(3.8013 0 0 3.8013 26.286 29.143)"
        fill="#9feaf9"
        stroke="transparent"
      >
        <path
          d="m100.5 71.69c-26.005-4.736-46.567 0.221-54.762 14.415-6.115 10.592-4.367 24.635 4.24 39.646a2.667 2.667 0 1 0 4.626-2.653c-7.752-13.522-9.261-25.641-4.247-34.326 6.808-11.791 25.148-16.213 49.187-11.835a2.667 2.667 0 0 0 0.956-5.247zm-36.999 72.307c10.515 11.555 24.176 22.394 39.756 31.388 37.723 21.78 77.883 27.601 97.675 14.106a2.667 2.667 0 1 0-3.005-4.406c-17.714 12.078-55.862 6.548-92.003-14.318-15.114-8.726-28.343-19.222-38.478-30.36a2.667 2.667 0 1 0-3.945 3.59z"
        />
        <path
          d="m194.62 140.75c17.028-20.116 22.973-40.348 14.795-54.512-6.017-10.423-18.738-15.926-35.645-16.146a2.667 2.667 0 0 0-0.069 5.333c15.205 0.198 26.165 4.939 31.096 13.48 6.792 11.765 1.49 29.807-14.248 48.399a2.667 2.667 0 1 0 4.071 3.446zm-43.761-68.175c-15.396 3.299-31.784 9.749-47.522 18.835-38.942 22.483-64.345 55.636-60.817 79.675a2.667 2.667 0 1 0 5.277-0.775c-3.133-21.344 20.947-52.769 58.207-74.281 15.267-8.815 31.135-15.06 45.972-18.239a2.667 2.667 0 1 0-1.117-5.215z"
        />
        <path
          d="m87.77 187.75c8.904 24.86 23.469 40.167 39.847 40.167 11.945 0 22.996-8.143 31.614-22.478a2.667 2.667 0 1 0-4.571-2.748c-7.745 12.883-17.258 19.892-27.043 19.892-13.605 0-26.596-13.652-34.825-36.63a2.667 2.667 0 1 0-5.021 1.797zm81.322-4.863c4.61-14.728 7.085-31.718 7.085-49.423 0-44.179-15.463-82.263-37.487-92.042a2.667 2.667 0 0 0-2.164 4.874c19.643 8.723 34.317 44.866 34.317 87.168 0 17.177-2.397 33.63-6.84 47.83a2.667 2.667 0 1 0 5.09 1.593zm50.224-2.612c0-7.049-5.714-12.763-12.763-12.763s-12.763 5.714-12.763 12.763 5.714 12.763 12.763 12.763 12.763-5.714 12.763-12.763zm-5.333 0a7.43 7.43 0 1 1-14.86 0 7.43 7.43 0 0 1 14.86 0zm-165.49 12.763c7.05 0 12.764-5.714 12.764-12.763s-5.715-12.763-12.764-12.763c-7.048 0-12.763 5.714-12.763 12.763s5.715 12.763 12.763 12.763zm0-5.333a7.43 7.43 0 1 1 0-14.86 7.43 7.43 0 0 1 0 14.86z"
        />
        <path
          d="m127.62 54.444c7.049 0 12.763-5.714 12.763-12.763s-5.714-12.763-12.763-12.763-12.763 5.714-12.763 12.763 5.714 12.763 12.763 12.763zm0-5.333a7.43 7.43 0 1 1 0-14.86 7.43 7.43 0 0 1 0 14.86zm1.949 93.382c-4.985 1.077-9.896-2.091-10.975-7.076a9.236 9.236 0 0 1 7.076-10.976c4.985-1.077 9.896 2.091 10.976 7.076 1.077 4.985-2.091 9.897-7.077 10.976z"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{:else if id == "java"}
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(20.108 -84.429)" stroke-width=".7785">
        <g>
          <path
            d="m362.31 866.34s-37.733 21.943 26.853 29.368c78.244 8.926 118.23 7.646 204.46-8.6737 0 0 22.67 14.215 54.329 26.526-193.3 82.843-437.47-4.7984-285.64-47.221"
            fill="#5382a1"
          />
          <path
            d="m338.69 758.24s-42.321 31.327 22.313 38.012c83.583 8.6227 149.59 9.328 263.81-12.666 0 0 15.798 16.016 40.639 24.775-233.71 68.339-494.01 5.3891-326.76-50.122"
            fill="#5382a1"
          />
          <path
            d="m537.81 574.85c47.628 54.835-12.514 104.18-12.514 104.18s120.94-62.431 65.395-140.61c-51.873-72.906-91.652-109.13 123.7-234.03 0 0-338.03 84.424-176.58 270.45"
            fill="#f8981d"
          />
        </g>
        <g fill="#5382a1">
          <path
            d="m793.46 946.3s27.923 23.007-30.752 40.807c-111.57 33.8-464.38 44.006-562.39 1.3469-35.231-15.327 30.837-36.597 51.62-41.06 21.674-4.6999 34.06-3.8244 34.06-3.8244-39.181-27.601-253.25 54.196-108.74 77.622 394.11 63.913 718.42-28.78 616.19-74.891"
          />
          <path
            d="m380.46 646.23s-179.46 42.625-63.551 58.103c48.94 6.5524 146.5 5.0698 237.38-2.5441 74.268-6.2649 148.84-19.585 148.84-19.585s-26.188 11.215-45.134 24.152c-182.24 47.928-534.29 25.632-432.94-23.393 85.712-41.432 155.4-36.732 155.4-36.732"
          />
          <path
            d="m702.38 826.17c185.25-96.264 99.599-188.77 39.814-176.31-14.654 3.0501-21.187 5.693-21.187 5.693s5.4399-8.5219 15.83-12.211c118.27-41.581 209.23 122.64-38.18 187.68 0 3e-3 2.8663-2.5602 3.7233-4.8517"
          />
        </g>
        <path
          d="m590.69 102.73s102.6 102.63-97.308 260.45c-160.3 126.6-36.554 198.78-0.0667 281.25-93.571-84.424-162.24-158.74-116.17-227.91 67.618-101.53 254.94-150.76 213.55-313.78"
          fill="#f8981d"
        />
        <path
          d="m398.66 1087c177.82 11.383 450.88-6.3155 457.35-90.455 0 0-12.431 31.897-146.96 57.228-151.77 28.562-338.96 25.227-449.99 6.922 3e-3 0 22.728 18.811 139.6 26.305"
          fill="#5382a1"
        />
      </g>
    </g>
    {#if title}
      <title>{title}</title>
    {/if}
  </svg>
{/if}

<style>
  svg {
    stroke: var(--clr-highlight);
    fill: var(--clr-highlight);
  }
</style>
