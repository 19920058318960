<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.js";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>Explore2</title>
  <meta
    name="description"
    content="Website to explore data from the Explore2 project in plots and musics generated from the data."
  />
</svelte:head>

<div class="content">
  <h3>Overview:</h3>
  <p>
    Le site web <a href="https://explore2enmusique.github.io/" target="_blank"
      >Explore2 <i>in music</i></a
    >
    allows the exploration of simulations, through graphs and musics, of river flows
    from a sample of French watersheds over the period 1976-2100, data from the
    <b>Explore2</b>
    project.
  </p>
  <p>
    The work done for the design of this website can be summarized as follows:
  </p>
  <ol>
    <li>
      the <a
        href="https://explore2enmusique.github.io/a_propos"
        target="_blank"
      >
        data sonification
      </a> which enabled the making of the videos of the animated graphics synchronized
      with the generated music
    </li>
    <li>
      the making of a static website to browse these videos for different
      watersheds on an interactive map.
    </li>
  </ol>

  <p>
    Do not hesitate to go checkout the website with a good headset or good
    speakers. Besides, if you're interested in others sonification projects,
    also take a look at the project <a href="./hydrosounds"
      >Hydrological Soundscapes
    </a>.
  </p>
  <div class="imgae-container" bind:this={container}></div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/explore2/2024-07-24 12-48-11 - small.mp4
"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
    /* position: relative; */
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content b {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
