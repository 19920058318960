<script>
  import { onMount } from "svelte";
  // import { setupMockup } from "./Mockup.ts";
  // import ImageViewer from "./components/ImageViewer.svelte";
  // import App from "./App.svelte";
  import { setupMockup } from "../../3d/Mockup.ts";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>Surcote</title>
  <meta
    name="description"
    content="Surcote is a QGIS plugin which aims at estimating the spatial extent of an extrem flood from a known flood extent. I contributed to the plugin by developing pre-processing tools for the geographical data inputs needed by the Surcote."
  />
</svelte:head>

<div class="content">
  <h3>Overview:</h3>
  <p>
    Surcote is a <a href="https://qgis.org/" target="_blank" title="QGIS"
      >QGIS</a
    > plugin which aims at estimating the spatial extent of an extrem flood from
    a known flood extent. The plugin is the result of researches made by researchers
    from Inrae (National Research Institute for Agriculture, Food and the Environment).
    My contribution to the plugin is the development of pre-processing tools for
    the geographical data inputs it needs.
  </p>

  <p>
    In practice, I created a new tab within the plugin where 6 processing
    functionnalities (see below) are available to the user to pre-process
    geographical data (both raster and vector layers) before their use in the
    core of the plugin.
  </p>

  <p>
    The source code and the last version of the plugin is not yet available. I
    hope it will be available soon so you can check it out.
  </p>

  <div class="imgae-container" bind:this={container}>
    <!-- <h2>Hello</h2> -->
  </div>
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/surcote/2022-05-26 15-21-19-1.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>In a few words:</h3>
  <ul>
    <li>
      Implementation of both basic and advanced <span>
        processing algorithms
      </span> for raster and vector geographical layers.
    </li>
    <li>
      <span> Intuitive </span> and <span> reactive</span> graphical user interface.
    </li>
    <li>
      Transparent integration to the QGIS software with <span>
        parallelization
      </span>
      of the most computer intensive tasks, <span>progress bar</span> to monitor
      progress and <span>useful messages</span>
      (information, warnings and errors) displayed to the user at all steps.
    </li>
    <li>
      <ul>
        <span> Implemented processing:</span>
        <li>
          Creating cross sectional profile of type "line" from "points" (e.g.
          transform the topographic data produced by surveyor for several lines
          intersecting the river bed into cross sectional profile lines )
        </li>
        <li>
          Creating a virtual (VRT) digital elevation model (DEM) from a folder
          containing raster layers (DEM data for large areas are typically given
          as multiple raster layers).
        </li>
        <li>
          Creating a DEM from point data (e.g. in the case where very precise
          elevation data is acquired by a surveyor)
        </li>
        <li>Merging DEM of different resolutions</li>
        <li>
          Cutting/clipping vector and raster layers accoring to a spatial extent
          defined from a vector layer.
        </li>
        <li>
          Automatic extension of cross section profile that are too short (e.g.
          that do not cross the known flood extent).
        </li>
      </ul>
    </li>
  </ul>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
  }
  @media (min-width: 1600px) {
    .imgae-container {
      /* background-color: red; */
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  /* .container {
    height: 600px;
    width: 100%;
  } */
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
