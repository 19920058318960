<script>
  import { onMount } from "svelte";
  import { setupMockup } from "../../3d/Mockup.js";
  // import ImageViewer from ".../components/ImageViewer.svelte";

  onMount(async () => {
    let mockup = await setupMockup(container, videoElement);
    mockup.render();
    mockup.startAnimation();
  });

  let container, videoElement;
</script>

<svelte:head>
  <title>BaRatinAGE v3</title>
  <meta
    name="description"
    content="Graphical user interface for creating rating curves following a bayesian approach."
  />
</svelte:head>

<div class="content">
  <h3>Overview:</h3>
  <p>
    BaRatinAGE (Bayesian RATINg curve Advance Graphical Environment) is a
    graphical interface to a Bayesian method for estimating rating curves
    (relations between water height and flow in rivers) and associated
    uncertainties. Il is used worldwide (translated in 27 languages), by
    operational services in charge of rivers monitoring, researchers, students,
    etc...
  </p>
  <p>
    The software, in its v2.2 version, is currently being used worldwide
    (translated in 27 languages), by operational services in charge of rivers
    monitoring, researchers, students, etc...
  </p>
  <p>
    <span>BaRatinAGE v3</span> is a full rewrite of BaRatinAGE v2 undertaken at
    the request of researchers from Inrae. This rewrite aimed at (1) takin into
    account recent developments in the underlying Bayesian method,
    <span>BaRatin method</span>, (2) laying the foundations for a generalization
    of the graphical interface to other rating curve models and (3) modernizing
    the software and improving many ergonomic aspects.
  </p>
  <p>
    BaRatinAGE is free and Open Source. It can be downloaded from <a
      href="https://github.com/BaRatin-tools/BaRatinAGE/releases/latest"
    >
      Github
    </a>. For more information on the BaRatin method and its graphical user
    interface BaRatinAGE, please visit
    <a href="https://baratin-tools.github.io/en/">
      https://baratin-tools.github.io/en/
    </a>.
  </p>
  <!-- <p>
    The 3.0.0-beta0 version can be <a href="https://github.com/BaRatin-tools/BaRatinAGE/releases" target="_blank"  title="Publication GitHub de BaRatinAGE v3.0.0-beta0">downloaded</a> now if you want to check out the software.
  </p> -->
  <div class="imgae-container" bind:this={container} />
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    src="/images/projects/baratinage/2024-01-29 16-55-38.mp4"
    autoplay
    loop
    style="display: none;"
    id="video"
    bind:this={videoElement}
  />
  <h3>In a few words:</h3>
  <ul>
    <li>
      implementation of the jbam package, a <span>java API</span> to configure and
      control the BaM executable
    </li>
    <li>
      implementation of a <span>Swing graphical interface</span>, with very
      advanced code factorization to make it as generic as possible; the goal is
      to be able to easily extend the grapgical interface to add new rating
      curve models or even models that are outside of this scope.
    </li>
    <li>
      <span>reactive</span> graphical interface: user actions are automatically
      propagated to the rest of the interface,
      <span>synchronicity</span> problems between configurations and results are
      safely managed,
      <span>interactive graphics</span> to present the results, language change without
      restarting...
    </li>
    <li>
      packaging as a <span>stand-alone executable</span> (no need to have Java, no
      need to install), saving/loading of project files, import of BaRatinAGE v2
      projects, custom icons, ...
    </li>
  </ul>
</div>

<style>
  * {
    font-size: 0.9rem;
  }
  .content {
    width: 100%;
    /* position: relative; */
  }
  h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
  h3::after {
    content: "";
    background-color: white;
    width: 100%;
    height: 1px;
    flex-shrink: 100000000000000000;
  }
  /* h3 + * {
    padding-bottom: 0.5rem;
  } */
  p {
    padding: 0.25rem 0;
  }
  li {
    padding-bottom: 0.25rem;
  }
  .imgae-container {
    height: 600px;
    width: 100%;
    /* background-color: red; */
  }
  @media (min-width: 1600px) {
    .imgae-container {
      position: absolute;
      left: calc(960px + 1rem);
      left: calc(960px + 1rem + (100% - 960px - 1rem) / 2);
      transform: translateX(-50%);
      top: 12.5rem;
      height: 600px;
      width: calc(100% - 960px - 1rem);
      max-width: calc(0.9 * 100vh);
    }
  }
  .content span {
    color: var(--clr-highlight);
    font-weight: 400;
  }
  a {
    border-bottom: 1px dashed var(--clr-highlight);
    transition: border-bottom 100ms ease;
  }
  a:hover {
    border-bottom: 1px solid var(--clr-highlight);
  }
</style>
