<script>
  import ProjectCard from "../../components/ProjectCard.svelte";
  import { projectStore } from "../../store";
</script>

<div class="container">
  {#each Object.keys($projectStore) as project}
    <ProjectCard project={$projectStore[project]} />
  {/each}
</div>

<style>
  div.container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding-top: 1rem;
    width: 100%;
    animation: 250ms linear 0s normal blurin;
  }
  @keyframes blurin {
    from {
      filter: blur(2rem);
    }
    to {
      filter: blur(0);
    }
  }
</style>
