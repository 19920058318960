<script>
  import { locale, _ } from "svelte-i18n";
  import { link } from "svelte-routing";
  let localeChangeTarget = "";
  let localeChangeText = "English";
  let localeChangeImage = "united-kingdom-flag-square-xs.png";
  export let path;

  // function handleLocaleChange() {
  $: {
    console.log("-------------------------------");
    const u = path.split("/").filter((c) => c !== "");
    console.log("$locale", $locale);
    console.log(u);
    // console.log(u.pathname.split("/")[1]);
    // $locale = u.pathname.split("/")[1] === "en" ? "en" : "fr";
    // console.log("$locale", $locale);
    if ($locale === "fr") {
      localeChangeTarget = `/en${u.reduce((a, c) => `${a}/${c}`, "")}`;
      localeChangeText = "English";
      localeChangeImage = "united-kingdom-flag-square-xs.png";
    } else {
      localeChangeTarget = u
        .filter((c) => c !== "en")
        .reduce((a, c) => `${a}/${c}`, "");
      localeChangeTarget =
        localeChangeTarget === ""
          ? `/${localeChangeTarget}`
          : localeChangeTarget;
      localeChangeText = "Français";
      localeChangeImage = "france-flag-square-xs.png";
      console.log("u", u);
      console.log("localeChangeTarget", localeChangeTarget);
    }
  }

  // handleLocaleChange();

  function handleLocaleChange() {
    if ($locale === "fr") {
      $locale = "en";
    } else {
      $locale = "fr";
    }
  }
</script>

<!-- 
<div class="container">
  <input type="radio" name="locale" id="fr" value="fr" bind:group={$locale} />
  <label for="fr">Français</label>
  <input type="radio" name="locale" id="en" value="en" bind:group={$locale} />
  <label for="en">English</label>
</div> -->

<a href={localeChangeTarget} use:link on:click={handleLocaleChange}>
  <img
    src={`/images/${localeChangeImage}`}
    alt="localeChangeText"
    title={localeChangeText}
  />
  <span>{localeChangeText}</span>
</a>

<style>
  /* .container {
    position: fixed;
    inset: 1rem 1rem auto auto;
    z-index: 1;
  } */

  a {
    position: fixed;
    inset: 0.5rem 1rem auto auto;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  img {
    width: 1rem;
    border-radius: 50%;
  }
  span {
    font-size: 0.8rem;
  }
</style>
